import * as _ from 'lodash';
import moment from 'moment';
import querystring from 'querystring';
import axios from 'axios';
import {
    BASE_URL_RELIANCE,
    DOMAIN_COOKIE_NAME,
    getCookie,
    DAARA_COOKIE_NAME,
    DAARA_ENROLLEE_BASE_URL,
    DAARA_REFRESH_TOKEN
} from './utilityService';
import daaraConfig from './daara-config.json';
import { sendHttpGetRequest } from './axiosInterceptor';

const BASE_URL = BASE_URL_RELIANCE;

class UserService {
    constructor() {
        this.ax = axios.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
                DAARA_TOKEN: getCookie(DAARA_COOKIE_NAME)
            }
        });
        this.data = {};
        this.accessToken = getCookie(DOMAIN_COOKIE_NAME);
        this.daaraToken = getCookie(DAARA_COOKIE_NAME);
    }

    login(values) {
        return this.ax.post(`/enrollees/login`, values).then((response) => {
            return mapUser(response.data);
        });
    }

    auth() {
        let accessToken = getCookie(DOMAIN_COOKIE_NAME);
        let daaraToken = getCookie(DAARA_COOKIE_NAME);
        let refreshToken = getCookie(DAARA_REFRESH_TOKEN);
        return this.ax
            .post(`${BASE_URL_RELIANCE}/auth/enrollees`, null, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                let user = mapUser(response.data);
                user.credentials.accessToken = accessToken;
                user.credentials.daaraToken = daaraToken;
                user.credentials.refreshToken = refreshToken;
                return user;
            });
    }

    resetPassword(values) {
        this.data = querystring.stringify(values);
        return this.ax.post('/accounts/recover-password', this.data).then(() => {
            return true;
        });
    }

    changePassword(values) {
        this.data = values;
        return this.ax
            .post('/accounts/change-password', this.data, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then(() => {
                return true;
            });
    }

    getProfile() {
        return this.ax
            .get(`/enrollees/profile`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    getBenefits() {
        if (daaraConfig.features.enrollee_service.get_enrollee_benefits) {
            return sendHttpGetRequest(`${DAARA_ENROLLEE_BASE_URL}/enrollees/benefits`).then(
                (response) => {
                    return response.data;
                }
            );
        }
        return this.ax
            .get(`enrollees/benefits`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    getSecondaryCareBenefits() {
        return this.ax
            .get(`enrollees/secondary-care/all`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    requestSecondaryCare(benefitId, providerTypeId, dateTime) {
        this.data = {
            benefit_id: benefitId,
            provider_id: providerTypeId,
            date_time: dateTime
        };
        return this.ax
            .post(`enrollees/secondary-care/request`, this.data, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return {
                    paCode: response.data.data ? response.data.data.pacode : null,
                    message: response.data.message
                };
            });
    }

    getPlanDetails() {
        return this.ax
            .get(`enrollees/subscription`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    renewPlan(invoiceRefCode) {
        return this.ax.get(`payment/manual/${invoiceRefCode}`).then((response) => {
            return response.data.data;
        });
    }
    getUsageData() {
        return this.ax.get(`enrollees/usage`, {
        headers: {
            "Authorization": `Bearer ${this.accessToken}`
        }
        })
        .then(response => {
            const summary = response.data.data.summary;
            return {
            tipsRead: summary.total_tips_read,
            questionsAsked: summary.total_questions_asked,
            hospitalVisits: summary.total_hospital_visits,
            }
        })
        .catch( error=> console.log(error))
    }

    resetPlan(invoiceRefCode) {
        return this.ax.post(`/retail/renew/${invoiceRefCode}`).then((response) => {
            return response.data.data;
        });
    }

    getUsageData() {
        return this.ax
            .get(`enrollees/usage`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                const summary = response.data.data.summary;
                return {
                    tipsRead: summary.total_tips_read,
                    questionsAsked: summary.total_questions_asked,
                    hospitalVisits: summary.total_hospital_visits
                };
            });
    }

    sendReferralCodeToPhoneNumbers(phone_numbers) {
        return this.ax
            .post(
                '/enrollees/referral/send-sms',
                { phone_numbers },
                {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                }
            )
            .then((response) => {
                return response.data.data;
            });
    }

    getReferrals({ page, status, name }) {
        return this.ax
            .get(`/enrollees/referral?${querystring.stringify({ page, status, name })}`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    getReferralOverview() {
        return this.ax
            .get('/enrollees/referral/overview', {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    withdrawCash(amount, bank_id, account_name, account_number) {
        return this.ax
            .post(
                '/enrollees/referral/cash-out',
                {
                    amount,
                    bank_id,
                    account_name,
                    account_number
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                }
            )
            .then((response) => {
                return response.data.data;
            });
    }

    getBanks() {
        return this.ax.get('/utility/banks').then((response) => {
            return response.data.data;
        });
    }

    verifyAccountNumber(bank_id, account_number) {
        return this.ax
            .post(
                '/verify/account-number',
                {
                    bank_id,
                    account_number
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                }
            )
            .then((response) => {
                return response.data.data;
            });
    }

    unhashParams(hash) {
        if (hash) {
            this.data = {
                hash: hash
            };
            return this.ax.post('/enrollees/unhash', this.data).then((response) => {
                return {
                    email_address: response.data.data.email_address,
                    phone_number: response.data.data.phone_number,
                    first_name: response.data.data.first_name,
                    last_name: response.data.data.last_name,
                    is_family_plan: response.data.data.is_family_plan,
                    company_name: response.data.data.company_name,
                    providers: response.data.data.providers
                };
            });
        } else {
            return null;
        }
    }

    register(values) {
        //delete profile picture file
        delete values.profile_picture;
        //delete access_token from post body

        return this.ax
            .post(`/enrollees/profile`, values, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data;
            });
    }
}

function mapUser(json) {
    console.log(json);
    return {
        id: _.get(json, 'data.user_id'),
        hmoId: _.get(json, 'data.hmo_id'),
        emailAddress: _.get(json, 'data.email_address'),
        firstName: _.get(json, 'data.first_name'),
        lastName: _.get(json, 'data.last_name'),
        sex: _.get(json, 'data.sex'),
        otherNames: _.get(json, 'data.other_names'),
        dob: _.get(json, 'data.date_of_birth'),
        age: moment().diff(_.get(json, 'data.date_of_birth'), 'years'),
        package: _.get(json, 'data.package'),
        plan: _.get(json, 'data.plan'),
        planType: _.get(json, 'data.plan_type'),
        userType: _.get(json, 'data.user_type'),
        imageUrl: _.get(json, 'data.profile_picture'),
        referralUrl: _.get(json, 'data.referral_url'),
        isLoggedIn: false,
        credentials: {
            accessToken: _.get(json, 'data.access_token'),
            daaraToken: _.get(json, 'data.daara_token'),
            fcmToken: '',
            kangpeToken: ''
        },
        preferences: {
            allowPushNotifications: true,
            showCyclePlanner: true
        }
    };
}

export default new UserService();
