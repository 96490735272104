import React from 'react';
import TOUR_IMG from '../../img/consult_tour.gif';

export default [
    {
        selector: '[data-tut="step_one_tour"]',
        content: ({ goTo, skipHandler }) => (
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <h4
                        style={{
                            color: '#fff',
                            fontSize: '18px',
                            fontWeight: '700',
                            lineHeight: '22px',
                            marginBottom: '12px'
                        }}
                    >
                        Dashbord
                    </h4>
                </div>
                <p
                    style={{
                        color: '#fff',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        marginBottom: '16px'
                    }}
                >
                    Here you have a brief overview of your account information
                </p>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <p
                        style={{
                            color: '#fff',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '17px'
                        }}
                    >
                        Step 1/3
                    </p>
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <button
                            style={{
                                background: '#00508E',
                                padding: '8px 32px',
                                borderRadius: '5px',
                                fontSize: '14px',
                                display: 'block',
                                cursor: 'pointer',
                                border: '0',
                                color: '#fff'
                            }}
                            onClick={() => goTo(1)}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        ),
        style: {
            padding: '24px 32px',
            maxWidth: '407px',
            background: '#003F75',
            height: '170px'
        }
    },
    {
        selector: '[data-tut="step_two_tour"]',
        content: ({ goTo }) => (
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <h4
                        style={{
                            color: '#fff',
                            fontSize: '18px',
                            fontWeight: '700',
                            lineHeight: '22px',
                            marginBottom: '12px'
                        }}
                    >
                        Consult a Doctor
                    </h4>
                </div>
                <p
                    style={{
                        color: '#fff',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        marginBottom: '16px'
                    }}
                >
                    The GIF below helps you understand how to consult a doctor on the Reliance
                    webapp.
                </p>
                <img
                    style={{
                        width: '100%',
                        heigth: '100px',
                        marginBottom: '16PX'
                    }}
                    src={TOUR_IMG}
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <p
                        style={{
                            color: '#fff',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '17px'
                        }}
                    >
                        Step 2/3
                    </p>
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <button
                            style={{
                                background: '#00508E',
                                padding: '8px 32px',
                                borderRadius: '5px',
                                fontSize: '14px',
                                display: 'block',
                                cursor: 'pointer',
                                border: '0',
                                color: '#fff',
                                marginRight: '8px'
                            }}
                            onClick={() => goTo(0)}
                        >
                            Prev
                        </button>
                        <button
                            style={{
                                background: '#00508E',
                                padding: '8px 32px',
                                borderRadius: '5px',
                                fontSize: '14px',
                                display: 'block',
                                cursor: 'pointer',
                                border: '0',
                                color: '#fff'
                            }}
                            onClick={() => goTo(2)}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        ),
        style: {
            padding: '24px 32px',
            maxWidth: '407px',
            background: '#003F75'
        }
    },
    {
        selector: '[data-tut="step_three_tour"]',
        content: ({ goTo }) => (
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <h4
                        style={{
                            color: '#fff',
                            fontSize: '18px',
                            fontWeight: '700',
                            lineHeight: '22px',
                            marginBottom: '12px'
                        }}
                    >
                       Activity Statistics
                    </h4>
                </div>
                <p
                    style={{
                        color: '#fff',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        marginBottom: '16px'
                    }}
                >
                    Here you will find your activity statistics including your hospital visits (including telemedicine consultations)
                </p>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <p
                        style={{
                            color: '#fff',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '17px'
                        }}
                    >
                        Step 3/3
                    </p>
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <button
                            style={{
                                background: '#00508E',
                                padding: '8px 32px',
                                borderRadius: '5px',
                                fontSize: '14px',
                                display: 'block',
                                cursor: 'pointer',
                                border: '0',
                                color: '#fff',
                                marginRight: '8px'
                            }}
                            onClick={() => goTo(1)}
                        >
                            Prev
                        </button>
                        <button
                            style={{
                                background: '#00508E',
                                padding: '8px 32px',
                                borderRadius: '5px',
                                fontSize: '14px',
                                display: 'block',
                                cursor: 'pointer',
                                border: '0',
                                color: '#fff'
                            }}
                            // onClick={() => goTo(3)}
                            onClick={() => (window.location.href = '/tips')}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        ),
        style: {
            padding: '24px 32px',
            maxWidth: '407px',
            background: '#003F75'
        }
    },
];
