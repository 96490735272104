import React from 'react';

export default [
    {
        selector: '[data-tut="step_four_tour"]',
        content: () => (
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <h4
                        style={{
                            color: '#fff',
                            fontSize: '18px',
                            fontWeight: '700',
                            lineHeight: '22px',
                            marginBottom: '12px'
                        }}
                    >
                      Consultation
                    </h4>
                </div>
                <p
                    style={{
                        color: '#fff',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        marginBottom: '16px'
                    }}
                >
                    Click here to speak with a doctor.
                </p>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <p
                        style={{
                            color: '#fff',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '17px'
                        }}
                    >
                        Step 1/1
                    </p>
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <button
                            style={{
                                background: '#00508E',
                                padding: '8px 32px',
                                borderRadius: '5px',
                                fontSize: '14px',
                                display: 'block',
                                cursor: 'pointer',
                                border: '0',
                                color: '#fff'
                            }}
                            onClick={() => (window.location.href = '/refer')}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        ),
        style: {
            padding: '24px 32px',
            minWidth: '407px',
            background: '#003F75'
        }
    },
];
