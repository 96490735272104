import React from 'react';
import moment from 'moment';

const TextData = (props) => {
  return (
    <React.Fragment>
      <p> {props.message}</p>
      <div className="chat-item__message-time doctor-chat__time">
      {moment(props.created_at).format('HH:mm')}
      </div>
    </React.Fragment> 
  )
}

export default TextData;

