import React from 'react';

export default function ButtonBar({main, other, dataTut}) {
	return (
		<div className="dashboard-btn-section">
			<div data-tut={dataTut}>
			{
					other &&  
						<button className="dashboard__primary-btn" onClick={other.func}>
							{other.name}
						</button>
				}
				<button className="dashboard__secondary-btn" onClick={main.func} data-tut={dataTut}>
					{main.name}
				</button>
			</div>
		</div>
	)
}
