import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import moment from 'moment';
import * as _ from 'lodash';
import userService from '../services/userService';
import MainContent from './boxes/MainContent';
import PageHeader from './boxes/PageHeader';
import PageBody from './boxes/PageBody';
import LoadingScreen from './LoadingScreen';
import ButtonBar from './boxes/ButtonBar';
import StatsDataCard from './boxes/StatsDataCard';
import DashboardProfileCard from './boxes/DashboardProfileCard';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import tourDashboard from './tours/tourDashboard';
let hideFamilyButton = true;

class DashboardHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            usageData: {
                tipsRead: 0,
                questionsAsked: 0,
                hospitalVisits: 0
            },
            hasDependants:
                this.props.user.planInfo && this.props.user.planInfo.limits.dependants > 0,
            isLoading: false,
            isTourOpen: false,
            isShowingMore: false
        };
    }

    disableBody = (target) => disableBodyScroll(target);
    enableBody = (target) => enableBodyScroll(target);

    closeTour = () => {
        this.setState({ isTourOpen: false });
        localStorage.setItem('isTourOpen', false);
    };

    openTour = () => {
        this.setState({ isTourOpen: true });
    };

    componentDidMount() {
        if (localStorage.getItem('isTourOpen') == null) {
            localStorage.setItem('isTourOpen', true);
            this.setState({ isTourOpen: true });
        } else {
            let isTourOpenSync = localStorage.getItem('isTourOpen');
            this.setState({ isTourOpen: JSON.parse(isTourOpenSync) });
        }

        userService.getUsageData().then((data) => {
            this.setState({
                usageData: data
            });
        });
    }

	componentDidUpdate(prevProps) {
		if (prevProps.user?.planInfo?.limits?.dependants !== this.props.user?.planInfo?.limits?.dependants) {
		  this.setState({ hasDependants: this.props.user?.planInfo?.limits?.dependants > 0 });
		}
	  }

    addDependants = () => this.setState({ addDependants: true });
    render() {
        const { usageData, addDependants, hasDependants, isTourOpen } = this.state;
        const { user } = this.props;

        if (_.isEmpty(user)) return <LoadingScreen />;

        if (addDependants) {
            return (
                <Redirect
                    push
                    to={{
                        pathname: `/profile`,
                        state: {
                            addDependants: true
                        }
                    }}
                />
            );
        }

        return (
            <MainContent>
                <PageHeader title="Dashboard" />
                <PageBody>
                    <ButtonBar
                        dataTut="step_two_tour"
                        main={{
                            name: 'Consult a Doctor',
                            func: () => this.props.history.push('/consultations')
                        }}
                        // Temporarily hide the add dependant button
						other={hideFamilyButton ? null : hasDependants && {name: 'Add Family Members', func: this.addDependants}}
                    />

                    <div className="dashboard-refer-banner">
                        <button
                            className="dashboard-refer-banner__button"
                            onClick={() => this.props.history.push('/refer')}
                        >
                            <i className="fa fa-info-circle"></i> Invite friends, earn money! Tap
                            here
                        </button>
                    </div>

                    <DashboardProfileCard
                        image={user.imageUrl}
                        details={getUserProfileDetails(user)}
                    />

                    <div className="dashboard-card contact-us">
                        <h2>
                            If you have any problems at the hospital, please contact us via{' '}
                            <a href="mailto:hellonigeria@getreliancehealth.com">hellonigeria@getreliancehealth.com</a> or{' '}
                            <a href="tel:070073542623">0700-7354-2623</a>, and we will fix it
                            immediately.
                        </h2>
                    </div>

                    <div className="stats-data-wrap">
                        <div className="stats-data-card" data-tut="step_three_tour">
                            <StatsDataCard
                                name="Tips Read"
                                data={usageData.tipsRead}
                                iconClassName="fa-info-circle"
                            />
                            <StatsDataCard
                                name="Questions Asked"
                                data={usageData.questionsAsked}
                                iconClassName="fa-question-circle"
                            />
                            <StatsDataCard
                                name="Hospital Visits"
                                data={usageData.hospitalVisits}
                                iconClassName="fa-hospital"
                            />
                        </div>
                    </div>
                </PageBody>
                <Tour
                    onRequestClose={this.closeTour}
                    steps={tourDashboard}
                    isOpen={isTourOpen}
                    maskClassName="mask"
                    className="helper"
                    rounded={12}
                    onAfterOpen={this.disableBody}
                    onBeforeClose={this.enableBody}
                />
            </MainContent>
        );
    }
}

function getUserProfileDetails(user) {
    return {
        groupOne: [
            {
                name: 'Enrollee Name',
                value: `${user.firstName} ${user.lastName}`
            },
            {
                name: 'HMO ID',
                value: user.hmoId
            }
        ],
        groupTwo: [
            {
                name: 'Plan Info',
                value: user.planInfo
                    ? `${user.planInfo.product.name}`
                    : `${user.plan} ${user.planType}`
            },
            {
                name: 'Expires On',
                value: user.planInfo
                    ? moment(user.planInfo.cover_end_date).format('ddd, MMMM Do YYYY')
                    : 'Loading...'
            }
        ]
    };
}

export default withRouter(DashboardHome);
