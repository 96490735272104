import React, { Component } from 'react';
import Modal from '../boxes/Modal';
import LoadingScreen from '../../components/LoadingScreen';
import { ApiService, ENDPOINTS } from '../../services/ApiService';

class ReferralModal extends Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.userToken);
    this.state = {
      referralDetails: {},
      isLoading: true
    }
  }

  componentDidMount() {
    this.getReferralDetails();
  }

  getReferralDetails = () => {
    const { consultationId, referralId } = this.props;

    this.service.get(ENDPOINTS.consultations.referrals(consultationId, referralId))
      .then((response) => {
        this.setState({
          referralDetails: response.data,
          isLoading: false
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const { referralDetails, isLoading } = this.state;

    return (
      <Modal onClose={this.props.onClose} title="Referred Pharmacy Details">
        {
          isLoading ?
            <LoadingScreen/>
            :
            <React.Fragment>
              <div className="dashboard-card">
                <div className="profile-detail-wrap">
                  <div className="profile-item">
                    <div className="profile-item-wrapper">
                      {
                        referralDetails.instruction ?
                        <span>{referralDetails.instruction}</span>
                        :
                        <span>
                          For inquires, please contact our support team: <a href="mailto:hellonigeria@getreliancehealth.com"> hellonigeria@getreliancehealth.com</a>
                        </span>
                      }
                    </div>
                    <div className="profile-item-wrapper">
                      <p className="profile-item__label">Specialist Name</p>
                      <p className="profile-item__text">{referralDetails.specialist_name || 'N/A'}
                      </p>
                    </div>
                    <div className="profile-item-wrapper">
                      <p className="profile-item__label">Observation</p>
                      <p className="profile-item__text">{referralDetails.observation || 'N/A'}
                      </p>
                    </div>
                    <div className="profile-item-wrapper">
                      <p className="profile-item__label">Recommendation</p>
                      <p className="profile-item__text">{referralDetails.recommendation || 'N/A'}
                      </p>
                    </div>
                    <div className="profile-item-wrapper">
                      <button className="primary_button">
                        <a href={referralDetails.referral_note_url} target="_blank" rel="noopener noreferrer">Download Referral Note</a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
        }
      </Modal>
    )
  }
}

export default ReferralModal;