import React from 'react';

export default [
    {
        selector: '[data-tut="step_six_tour"]',
        content: ({ goTo }) => (
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <h4
                        style={{
                            color: '#fff',
                            fontSize: '18px',
                            fontWeight: '700',
                            lineHeight: '22px',
                            marginBottom: '12px'
                        }}
                    >
                        My Benefits
                    </h4>
                </div>
                <p
                    style={{
                        color: '#fff',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        marginBottom: '16px'
                    }}
                >
                    Here you can view a detailed list of your benefits
                </p>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <p
                        style={{
                            color: '#fff',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '17px'
                        }}
                    >
                        Step 1/4
                    </p>
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <button
                            style={{
                                background: '#00508E',
                                padding: '8px 32px',
                                borderRadius: '5px',
                                fontSize: '14px',
                                display: 'block',
                                cursor: 'pointer',
                                border: '0',
                                color: '#fff'
                            }}
                            onClick={() => goTo(1)}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        ),
        style: {
            padding: '24px 32px',
            minWidth: '407px',
            background: '#003F75'
        }
    },
    {
        selector: '[data-tut="step_benefit_search_tour"]',
        content: ({ goTo }) => (
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <h4
                        style={{
                            color: '#fff',
                            fontSize: '18px',
                            fontWeight: '700',
                            lineHeight: '22px',
                            marginBottom: '12px'
                        }}
                    >
                        Search
                    </h4>
                </div>
                <p
                    style={{
                        color: '#fff',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        marginBottom: '16px'
                    }}
                >
                   You can also search for a particular hospital here
                </p>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <p
                        style={{
                            color: '#fff',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '17px'
                        }}
                    >
                        Step 2/4
                    </p>
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <button 
                            style={{
                                background: '#00508E',
                                padding: '8px 32px',
                                borderRadius: '5px',
                                fontSize: '14px',
                                display: 'block',
                                cursor: 'pointer',
                                border: '0',
                                color: '#fff',
                                marginRight: '8px'
                            }}
                            onClick={() => goTo(0)}
                        >
                            Prev
                        </button>
                        <button
                            style={{
                                background: '#00508E',
                                padding: '8px 32px',
                                borderRadius: '5px',
                                fontSize: '14px',
                                display: 'block',
                                cursor: 'pointer',
                                border: '0',
                                color: '#fff'
                            }}
                            onClick={() => goTo(2)}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        ),
        style: {
            padding: '24px 32px',
            minWidth: '407px',
            background: '#003F75'
        }
    },
    {
        selector: '[data-tut="step_secondary_care_tour"]',
        content: ({ goTo }) => (
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <h4
                        style={{
                            color: '#fff',
                            fontSize: '18px',
                            fontWeight: '700',
                            lineHeight: '22px',
                            marginBottom: '12px'
                        }}
                    >
                        Access Secondary Care
                    </h4>
                </div>
                <p
                    style={{
                        color: '#fff',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        marginBottom: '16px'
                    }}
                >
                   You can click here to access Secondary Care like gym and spa with respect to your plan.
                </p>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <p
                        style={{
                            color: '#fff',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '17px'
                        }}
                    >
                        Step 3/4
                    </p>
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <button 
                            style={{
                                background: '#00508E',
                                padding: '8px 32px',
                                borderRadius: '5px',
                                fontSize: '14px',
                                display: 'block',
                                cursor: 'pointer',
                                border: '0',
                                color: '#fff',
                                marginRight: '8px'
                            }}
                            onClick={() => goTo(2)}
                        >
                            Prev
                        </button>
                        <button
                            style={{
                                background: '#00508E',
                                padding: '8px 32px',
                                borderRadius: '5px',
                                fontSize: '14px',
                                display: 'block',
                                cursor: 'pointer',
                                border: '0',
                                color: '#fff'
                            }}
                            onClick={() => goTo(3)}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        ),
        style: {
            padding: '24px 32px',
            minWidth: '407px',
            background: '#003F75'
        }
    },
    {
        selector: '[data-tut="step_fresh_chat_tour"]',
        content: ({ goTo }) => (
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <h4
                        style={{
                            color: '#fff',
                            fontSize: '18px',
                            fontWeight: '700',
                            lineHeight: '22px',
                            marginBottom: '12px'
                        }}
                    >
                        Live Chat
                    </h4>
                </div>
                <p
                    style={{
                        color: '#fff',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '20px',
                        marginBottom: '16px'
                    }}
                >
                   For enquiries, you can click here to chat with a customer care agent in real-time
                </p>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <p
                        style={{
                            color: '#fff',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '17px'
                        }}
                    >
                        Step 4/4
                    </p>
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <button
                            style={{
                                background: '#00508E',
                                padding: '8px 32px',
                                borderRadius: '5px',
                                fontSize: '14px',
                                display: 'block',
                                cursor: 'pointer',
                                border: '0',
                                color: '#fff'
                            }}
                            onClick={() => (window.location.href = '/settings')}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        ),
        style: {
            padding: '24px 32px',
            minWidth: '407px',
            background: '#003F75',
            transform: 'none',
            position: 'absolute',
            bottom: '30px',
            right: '97px',
            left: 'inherit',
            top: 'inherit',
        }
    }
];
