// src/context/FeatureFlagContext.js
import React, { createContext, useContext } from "react";
import { isFeatureEnabled } from "../components/utils/featureFlag";

export const FeatureFlagContext = createContext({});

export const FeatureFlagProvider = ({ children }) => {
  const featureFlags = {
    flags: {
      featureViewPrescription: isFeatureEnabled("view_prescription"),
    },
    // Add other flags here
  };

  return (
    <FeatureFlagContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagContext.Provider>
  );
};
