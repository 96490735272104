import React from 'react';
import moment from 'moment';
import { CONSULTATION_STATUS } from '../services/utilityService';
import ConsultationLastMessage from './ConsultationLastMessage';

const getConsultationStatus = (status) => {
    switch (parseInt(status)) {
        case 1:
            return 'orange';
        case 2:
            return 'green';
        default:
            return 'grey';
    }
};

export default function ConsultationList({
    list,
    msgStats,
    endConsultationConfirmations,
    handleChange
}) {
    return list.map((consultation, index) => (
        <div
            className="convo-thread-wrap"
            key={index}
            onClick={() => handleChange(consultation.id)}
        >
            <div className="convo-thread-wrap__main">
                <div style={{ minWidth: '7rem' }}>
                    <img
                        className="chat-doctor__img"
                        src={
                            consultation.doctor
                                ? consultation.doctor.profile_picture
                                : 'https://via.placeholder.com/150'
                        }
                        alt={'Doctor'}
                    />
                    <i
                        className={`fas fa-circle ${getConsultationStatus(
                            consultation.consultation_status
                        )} convo-thread-wrap__message-status`}
                    ></i>
                </div>
                <div style={{ flexGrow: '1', marginRight: '2rem' }}>
                    <p className="dashboard-card__title-text" style={{ marginBottom: '0.5rem' }}>
                        {consultation.doctor
                            ? `Dr. ${consultation.doctor.last_name}${
                                  consultation.reason === null ||
                                  consultation.reason === undefined ||
                                  consultation.reason === 'undefined'
                                      ? ''
                                      : ` - ${consultation.reason}`
                              }`
                            : 'No Doctor has been assigned to this consultation yet.'}
                    </p>
                    {endConsultationConfirmations[consultation.id] ||
                    parseInt(consultation.consultation_status) ===
                        CONSULTATION_STATUS.closePending ? (
                        <p
                            className="dashboard-card__body-text"
                            style={{ marginBottom: '0.5rem', fontSize: '1.5rem' }}
                        >
                            <strong>Doctor wants to end consultation. Kindly confirm</strong>
                        </p>
                    ) : (
                        <ConsultationLastMessage consultation={consultation} />
                    )}
                    <p
                        className="dashboard-card__body-text"
                        style={{ marginBottom: '0.5rem', fontSize: '1rem' }}
                    >
                        {moment(
                            consultation?.last_message?.created_at || consultation?.created_at
                        ).isSame(new Date(), 'day')
                            ? moment(
                                  consultation?.last_message?.created_at || consultation?.created_at
                              ).format('HH:mm')
                            : moment(
                                  consultation?.last_message?.created_at || consultation?.created_at
                              ).format('DD-MM-YYYY')}
                    </p>
                </div>

                {msgStats[consultation.id] && msgStats[consultation.id].length > 0 && (
                    <div className="chat-item__new-message-count">
                        {msgStats[consultation.id].length}
                    </div>
                )}

                {[3, 4, 5, 6].includes(consultation.consultation_status) &&
                    consultation?.doctor?.active_status !== 6 && (
                        <div>
                            <button
                                className="dashboard__primary-btn"
                                onClick={() => handleChange(consultation.id)}
                                disabled={consultation?.doctor?.active_status === 6}
                            >
                                Request Follow-up Consultation
                            </button>
                        </div>
                    )}
                <div>
                    <i className="fa fa-chevron-right" />
                </div>
            </div>
        </div>
    ));
}
