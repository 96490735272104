import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import ImageInput from '../inputs/ImageInput';
//import CheckboxGroup from '../inputs/CheckboxGroup';
import DateTime from 'react-datetime';
import './datepicker.css';
import moment from 'moment';
import '../../services/utilityService';
import UtilityService from '../../services/utilityService';
import LoadingScreen from '../LoadingScreen';
import FilterableSelect from '../inputs/FilterableSelect';
import { Calendar } from 'react-date-range';
import { enGB } from 'date-fns/locale';

class SignUpForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      lastPage: 4, //can vary depending on the enrollee type,
      isLoadingSection: { profilePicture: false },
      disabled: true,
      dobError: null,
    };
  }

  onNextPage = (e) => {
    const approvedAge = localStorage.getItem(`@enrolle_age`);

    e.preventDefault();
    let cPage = this.state.currentPage;
    let nextPage = ++cPage;

    if (this.state.currentPage === 1 && !this.props.dateOfBirth) {
      this.setState({
        dobError: 'Required',
      });

      --nextPage;
    } else {
      this.props.onError();
    }

    const companyName = this.props?.user?.company_name;
    const maxAge = getMaxAge(companyName);

    if (approvedAge > maxAge) {
      this.props.onError({
        message: `Kindly enter an age below ${maxAge} years old for a spouse or an age below 25 years old for children.`,
      });
    } else {
      //selected State check
      if (
        this.state.currentPage === 2 &&
        (!this.props.selectedState || Number(this.props.selectedState) === 0)
      ) {
        this.props.onError({ message: 'Please select a state' });
        --nextPage;
      } else {
        this.props.onError();
      }

      this.setState({
        currentPage: nextPage,
      });
    }
  };

  onPrevPage = () => {
    let cPage = this.state.currentPage;
    let prevPage = --cPage;
    //i.e skip company page, if dependant type is child

    this.setState({
      currentPage: prevPage,
    });
  };

  componentDidMount() {
    const { dispatch, user } = this.props;

    dispatch(change('signUpForm', 'first_name', user.first_name));
    dispatch(change('signUpForm', 'last_name', user.last_name));
    dispatch(change('signUpForm', 'email_address', user.email_address));
    dispatch(change('signUpForm', 'primary_phone_number', user.phone_number));
    if (user.hash) {
      dispatch(change('signUpForm', 'enrollee_type', user.enrolleeType));
      dispatch(change('signUpForm', 'hash', user.hash));
    } else if (user.accessToken) {
      dispatch(change('signUpForm', 'access_token', user.accessToken));
    }

    dispatch(change('signUpForm', 'has_smartphone', '1'));
  }

  onImageUploaded = (img) => {
    const { dispatch, user, onError } = this.props;
    //set isLoadingSection in state to true
    //upload img
    //get filename from server
    //change form value
    //set isLoadingSection to false

    this.setState({
      isLoadingSection: {
        ...this.state.isLoadingSection,
        profilePicture: true,
      },
    });

    UtilityService.uploadFile(img, 'profile_picture', user.accessToken)
      .then((response) => {
        dispatch(
          change('signUpForm', 'profile_picture_filename', response.filename)
        );
        dispatch(change('signUpForm', 'profile_picture', img));
        this.setState({
          isLoadingSection: {
            ...this.state.isLoadingSection,
            profilePicture: false,
          },
        });
      })
      .catch((e) => {
        onError({
          message: 'Unable to upload picture.',
          code: -1,
        });
        this.setState({
          isLoadingSection: {
            ...this.state.isLoadingSection,
            profilePicture: false,
          },
        });
        console.log('Error uploading image', e);
      });
  };

  onDateSet = (date) => {
    const { dispatch } = this.props;
    dispatch(
      change('signUpForm', 'date_of_birth', moment(date).format('YYYY-MM-DD'))
    );
  };

  onDateError = (error) => {
    this.setState({
      dobError: error,
    });
  };

  render() {
    const {
      error,
      selectedState,
      dateOfBirth,
      profilePicture,
      preferredHospitalLocation,
      data,
      enrolleeType,
      handleSubmit,
      user,
      pristine,
      submitting,
    } = this.props;
    const { currentPage, lastPage, isLoadingSection, disabled, dobError } =
      this.state;

    function getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    }

    localStorage.setItem(`@enrolle_age`, getAge(dateOfBirth));

    return (
      <form
        onSubmit={currentPage !== lastPage ? this.onNextPage : handleSubmit}
        className="form"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'evenly',
          }}
        >
          {[1, 2, 3, 4].map((x) => {
            return <PageIndicator key={x} isActive={x === currentPage} />;
          })}
        </div>
        {currentPage === 1 && (
          <PersonalInfoPage
            user={user}
            data={data}
            onDateSet={this.onDateSet}
            dob={dateOfBirth}
            dobError={dobError}
            onDateError={this.onDateError}
          />
        )}
        {currentPage === 2 && (
          <ContactInfoPage
            selectedState={selectedState}
            user={user}
            data={data}
          />
        )}
        {currentPage === 3 && (
          <MedicalInfoPage
            user={user}
            data={data}
            selectedState={selectedState}
            preferredHospitalLocation={preferredHospitalLocation}
          />
        )}
        {currentPage === 4 && (
          <ExtraInfoPage
            user={user}
            data={data}
            profilePicture={profilePicture}
            onImageSet={this.onImageUploaded}
            isLoadingPhoto={isLoadingSection.profilePicture}
            enrolleeType={enrolleeType}
          />
        )}

        {error && (
          <div
            style={{ backgroundColor: 'red', color: '#FFF', padding: '10px' }}
          >
            {error}
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
          }}
        >
          <Field
            component="input"
            type="hidden"
            name="page"
            value={currentPage}
          />
          {currentPage > 1 && (
            <input
              type="button"
              value="Previous"
              onClick={this.onPrevPage}
              style={{ float: 'left' }}
            />
          )}

          <div
            style={{ width: '100%', textAlign: 'center' }}
          >{`${currentPage} / ${lastPage}`}</div>

          {currentPage >= 1 && currentPage < lastPage && (
            <input type="submit" value="Next" style={{ float: 'right' }} />
          )}
          {/* {(currentPage >= 1 && currentPage < lastPage) &&
          <>
            {calculatedAge === 0 || calculatedAge > 65 ?
              <input className='next-btn' type="submit" value="Next" style={{float: "right"}} disabled={disabled}/>
            : <input className='next-btn' type="submit" value="Next" style={{float: "right"}} disabled={!disabled}/>}
          </>
          } */}
          {currentPage === lastPage && (
            <input
              type="submit"
              value="Submit"
              disabled={pristine || submitting}
              style={{ float: 'right' }}
            />
          )}
        </div>
      </form>
    );
  }
}

const Required = () => (
  <span style={{ color: 'red', fontWeight: 'bold' }}>*</span>
);

const PersonalInfoPage = (props) => {
  const { user, dob, dobError, onDateError } = props;
  const companyName = user?.company_name;

  const [showPicker, setShowPicker] = useState(false);

  const pickerRef = useRef(null);
  const pickerInputRef = useRef(null);

  const scrollOptions = {
    behavior: 'smooth',
    block: 'end',
  };

  useEffect(() => {
    onDateError(null);
    if (showPicker) {
      const view = pickerInputRef?.current.scrollIntoView(scrollOptions);
      return view;
    }
  }, [showPicker]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef?.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [pickerRef]);

  return (
    <div style={{ marginTop: '15px' }}>
      <h2 style={{ fontSize: '24px', color: '#094063' }}>
        Personal Information
      </h2>
      <p style={{ fontStyle: 'italic', fontSize: '10px', color: '#aaa' }}>
        <strong>NOTE:</strong>Fields marked (<Required />) are required.
      </p>
      <div style={{ marginTop: '10px' }}>
        <span>
          First Name <Required />:
          <Field
            component="input"
            type="text"
            name="first_name"
            placeholder="First Name"
            readOnly={!!user.first_name}
            required
          />
          <br />
        </span>
        <span>
          Last Name <Required />:
          <Field
            component="input"
            type="text"
            name="last_name"
            placeholder="Last Name"
            readOnly={!!user.last_name}
            required
          />
          <br />
        </span>
        <span>
          Gender <Required />:<br />
          <Field
            name="sex"
            component="input"
            type="radio"
            value="m"
          /> Male <Field name="sex" component="input" type="radio" value="f" />{' '}
          Female
          <br />
        </span>
        <span>
          <div className="dob-label">
            <span>
              Date of Birth <Required />:
            </span>
            <span>
              {dobError && <span className="dobError">{dobError}</span>}
            </span>
          </div>
          {getMaxAge(companyName) === AGE_LIMITS.corporate ? (
            <span
              style={{
                display: 'block',
                marginTop: '5px',
                color: 'red',
                fontSize: '12px',
              }}
            >
              Principals and spouses have an age limit of 65 years old
              <br />
              and children have an age limit of 25 years old
            </span>
          ) : null}
          <div ref={pickerRef} className="dobContainer">
            <input
              type="text"
              placeholder="DD-MM-YYYY"
              required
              onClick={() => setShowPicker(!showPicker)}
              name="date_of_birth"
              readOnly
              value={dob ? moment(dob).format('DD-MM-YYYY') : ''}
            />

            {showPicker && (
              <div ref={pickerInputRef}>
                <Calendar
                  minDate={companyTypeMaxAge(companyName)}
                  maxDate={new Date()}
                  locale={enGB}
                  onChange={(date) => {
                    props.onDateSet(date);
                    setShowPicker(false);
                  }}
                  date={dob ? new Date(dob) : new Date()}
                  className="form-control"
                />
              </div>
            )}
          </div>
          <br />
        </span>
      </div>
    </div>
  );
};

const ContactInfoPage = (props) => {
  const { data, user } = props;

  let providers = user.providers
    ? sortProvidersByState(user.providers)
    : sortProvidersByState(data.providers);

  //get unique states only
  providers = providers.reduce((b, i) => {
    if (b.filter((v) => v.state.name === i.state.name).length === 0) b.push(i);
    return b;
  }, []);

  return (
    <div style={{ marginTop: '15px' }}>
      <h2 style={{ fontSize: '24px', color: '#094063' }}>
        Contact Information
      </h2>
      <p style={{ fontStyle: 'italic', fontSize: '10px', color: '#aaa' }}>
        <strong>NOTE:</strong>Fields marked (<Required />) are required.
      </p>
      <div style={{ marginTop: '10px' }}>
        <span>
          Phone Number (Primary) <Required />:
          <Field
            component="input"
            name="primary_phone_number"
            type="phone"
            placeholder={
              user.accessToken
                ? 'Phone Number (Use yours, if not available)'
                : 'Primary Phone Number'
            }
            readOnly={!!user.phone_number}
            normalize={normalizePhone}
            required
          />
          <br />
        </span>
        <span>
          Email Address:
          <Field
            component="input"
            name="email_address"
            type="email"
            placeholder={
              user.accessToken
                ? 'Email Address (Leave empty, if unavailable)'
                : 'Email Address'
            }
            readOnly={!!user.email_address}
          />
          <br />
        </span>
        <span>
          Home Address <Required />:
          <Field
            component="input"
            name="home_address"
            type="text"
            placeholder="Home Address"
            required
          />
          <br />
        </span>
        <span>
          State of Residence <Required />
          <Field name="home_address_state_id" component="select" required>
            <option value="0">Please select a State</option>
            {providers.map((provider) => (
              <option key={provider.id} value={provider.state.id}>
                {provider.state.name}
              </option>
            ))}
          </Field>
        </span>
      </div>
    </div>
  );
};

const MedicalInfoPage = (props) => {
  const { data, user, selectedState } = props;
  let providers = [];
  if (user.providers) {
    providers = user.providers;
  } else {
    providers = data.providers;
  }

  providers = providers.filter(
    (provider) => provider.state.id === Number(selectedState)
  );

  let providerLocations = [];
  providers.map((provider) => providerLocations.push(provider.location));
  providerLocations = [...new Set(providerLocations)];

  return (
    <div style={{ marginTop: '15px' }}>
      <h2 style={{ fontSize: '24px', color: '#094063' }}>
        Choose a Preferred Hospital
      </h2>
      <p style={{ fontStyle: 'italic', fontSize: '10px', color: '#aaa' }}>
        <strong>NOTE:</strong>Fields marked (<Required />) are required.
      </p>
      <div style={{ marginTop: '10px' }}>
        {providers && providers.length > 0 ? (
          <div className="form-group">
            <Field
              name="preferred_provider_id"
              className="form-input"
              component={FilterableSelect}
              props={{
                items: providers,
                placeholder: 'Search by name, address or state',
                filterBy: ['name', 'address', 'state.name'],
                extraKey: 'address',
              }}
            />
          </div>
        ) : (
          <div>
            <span>
              Preferred Hospital <Required />:
              <Field
                component="input"
                type="text"
                name="preferred_hospital_name"
                placeholder="Preferred Hospital's Name"
              />
              <br />
            </span>
            <span>
              Hospital Address <Required />:
              <Field
                component="input"
                type="text"
                name="preferred_hospital_address"
                placeholder="Preferred Hospital's Address"
              />
              <br />
            </span>
          </div>
        )}

        <br />
      </div>
    </div>
  );
};

const ExtraInfoPage = (props) => {
  const { data, profilePicture, onImageSet, enrolleeType, isLoadingPhoto } =
    props;

  return (
    <div style={{ marginTop: '15px' }}>
      <h2 style={{ fontSize: '24px', color: '#094063' }}>Extra Information</h2>
      <p style={{ fontStyle: 'italic', fontSize: '10px', color: '#aaa' }}>
        <strong>NOTE:</strong>Fields marked (<Required />) are required.
      </p>
      <div style={{ marginTop: '10px' }}>
        {enrolleeType !== '3' && (
          <span>
            Marital Status:
            <Field name="marital_status_id" component="select">
              <option>Please select a Marital Status</option>
              {data.mStatuses.map((status) => (
                <option key={status.id} value={status.id}>
                  {status.name}
                </option>
              ))}
            </Field>
          </span>
        )}

        <Field component="input" type="hidden" name="profile_picture" />
        <Field
          component="input"
          type="hidden"
          name="profile_picture_filename"
        />

        {isLoadingPhoto ? (
          <div style={{ width: '250px', height: '250px' }}>
            <LoadingScreen />
          </div>
        ) : (
          <span>
            Please add a recent Passport Photograph <Required />
            <ImageInput
              width={250}
              height={250}
              customClassName="sign__form__file-input"
              onCropComplete={onImageSet}
              placeholder={profilePicture}
            />
          </span>
        )}

        <Field component="input" type="hidden" name="enrollee_type" />
        <Field component="input" type="hidden" name="hash" />

        <span>
          Do you own a Smart-phone (Android, iOS etc):
          <br />
          {'  '}
          <Field
            name="has_smartphone"
            component="input"
            type="radio"
            value="1"
          />{' '}
          Yes {'  '}
          <Field
            name="has_smartphone"
            component="input"
            type="radio"
            value="0"
          />{' '}
          No
        </span>
      </div>
    </div>
  );
};

const PageIndicator = (props) => {
  return (
    <div
      style={{
        backgroundColor: props.isActive ? '#094063' : '#e6e5e5',
        height: '5px',
        borderRadius: '3px',
        width: '100%',
        margin: '4px',
      }}
    ></div>
  );
};

const normalizePhone = (value) => {
  if (!value) {
    return value;
  }
  return value.replace(/[^\d]/g, '');
};

const sortProvidersByState = (uProviders) => {
  let providers = uProviders;
  for (let i = 0; i < providers.length; i++) {
    for (let j = 0; j < providers.length - 1 - i; j++) {
      if (providers[j].state) {
        if (providers[j].state.name > providers[j + 1].state.name) {
          let temp = providers[j + 1];
          providers[j + 1] = providers[j];
          providers[j] = temp;
        }
      }
    }
  }
  return providers;
};

const AGE_LIMITS = {
  retail: 120,
  corporate: 65,
};

function getMaxAge(companyName) {
  if (!companyName) return AGE_LIMITS.retail;

  return companyName.includes('RelianceHMO Retail')
    ? AGE_LIMITS.retail
    : AGE_LIMITS.corporate;
}

function companyTypeMaxAge(companyName) {
  return new Date(
    new Date().setFullYear(new Date().getFullYear() - getMaxAge(companyName))
  );
}

SignUpForm = reduxForm({
  form: 'signUpForm',
  destroyOnUnmount: false,
})(SignUpForm);

// Decorate with connect to read form values
const selector = formValueSelector('signUpForm');
SignUpForm = connect((state) => {
  // can select values individually
  const selectedState = selector(state, 'home_address_state_id');
  const dateOfBirth = selector(state, 'date_of_birth');
  const profilePicture = selector(state, 'profile_picture');
  const enrolleeType = selector(state, 'enrollee_type');
  const preferredHospitalLocation = selector(
    state,
    'preferred_hospital_location'
  );

  return {
    selectedState,
    dateOfBirth,
    profilePicture,
    enrolleeType,
    preferredHospitalLocation,
  };
})(SignUpForm);

export default SignUpForm;