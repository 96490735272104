import React from "react";
import PropTypes from "prop-types";
import Modal from "../boxes/Modal";
import DoctorIllustration from "../../img/Doctor_illustration.svg";
import googleLogo from "../../img/google-play-badge.png";
import Apple from "../../img/apple.png";

const Index = ({ title, isVisible, onClose }) => {
  return (
    <Modal
      title={title}
      onClose={onClose}
      open={isVisible}
      style={{
        fontWeight: "bold",
        fontSize: "2rem",
        maxWidth: "40rem",
        textAlign: "center",
        padding: 0,
        margin: 0,
      }}
    >
      <div style={{ maxWidth: "40rem", fontSize: "1.5rem" }}>
        <div
          style={{
            display: "flex",
            padding: "1rem",
            background: "#FFFFFA",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              backgroundColor: "#E0F1FF",
              fontSize: "1.1rem",
              padding: "4px 8px 4px 8px",
              marginBottom: 8,
              fontWeight: "bold",
            }}
          >
            <p>
              {" "}
              &#x24D8; Pleae note that you can only receive calls via the mobile
              app.
            </p>
          </div>
          <div className="" style={{ textAlign: "center" }}>
            {" "}
            Open the mobile app to recieve the call or download the mobile app
            from the Playstore or Appstore
          </div>

          <div style={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}>
            <img src={DoctorIllustration} alt="" />
          </div>

          <div
            className=""
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "2rem",
            }}
          >
            Get the Reliance Care app!
          </div>
        </div>
        <p
          style={{
            fontSize: "1.3rem",
            textAlign: "center",
            fontWeight: "normal",
          }}
        >
          You can login by using your HMO ID and password
        </p>
      </div>
      <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
        <div style={{ maxWidth: 100 }}>
          <a href="https://apps.apple.com/ng/app/reliance-care/id1538245836">
            <img src={Apple} width={"100%"} alt="" />
          </a>
        </div>
        <div className="" style={{ maxWidth: 130 }}>
          <a href="https://play.google.com/store/apps/details?id=com.reliancehmo.app&hl=en&gl=US">
            <img src={googleLogo} alt="" width={"100%"} />
          </a>
        </div>
      </div>
    </Modal>
  );
};

Index.propTypes = {
  onYes: PropTypes.instanceOf(Function),
  onClose: PropTypes.instanceOf(Function),
  title: PropTypes.string,
  content: PropTypes.string,
};

export default Index;
