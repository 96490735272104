import React from 'react';
import moment from 'moment';


const DeletedMessage = ({message, created_at}) => {
  return (
    <div style={{ textAlign: "center", display: "grid" }}>
      <div
        className=""
        style={{
          margin: 2,
          display: "inline-block",
          verticalAlign: "middle",
          borderRadius: 15,
          padding: 0,
        }}
      >
<i
                  className="fas fa-prescription-bottle-alt"
                  style={{
            display: "inline-block",
            verticalAlign: "middle",
            padding: 10,
            fontSize: 20,
            color: 'white',
            backgroundColor: "#094063",
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            border: "1px solid #094063"
          }}
                ></i>
      
      <div style={{
            fontSize: "1.5rem",
            display: "inline-block",
            verticalAlign: "middle",
            padding: 10,
            marginRight: 0,
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            borderRight: "1.3px solid #107BC0",
            backgroundColor: "white",
            border: "1px solid #094063",
          }}>

      <sub style={{fontWeight:"lighter"}}>{moment(created_at).format("HH:mm")} -  </sub>  {message} 
      </div>
     
      </div>
      </div>
    
  );
};

export default DeletedMessage;