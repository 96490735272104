import React from 'react';
import moment from 'moment';

const BubbleRight = (props) => {
    return (
    <div className="enrollee-chat">
        {statusIcon(props)}
        <div className="chat-item__message enrollee-chat__bubble">
            <p>{props.message}</p>
            <div className="chat-item__message-time enrollee-chat__time">{moment(props.created_at).format('HH:mm')}</div>
            {props.attachments && props.attachments.length > 0 &&
                <p style={{fontSize:"0.8rem",cursor:"pointer"}} onClick={()=>props.onViewAttachments(props.nextModal, {attachments: props.attachments})}>
                <i className="fa fa-paperclip" style={{color:"white"}}/>
                {`  ${props.attachments.length} file(s) attached. Click to view`}
                </p>
            }
        </div>
    </div>
    );
};

const statusIcon = (props) => {
    switch(props.status) {
        case 'sending':
            return <i className="fas fa-paper-plane" style={{display: "flex", flexDirection: "row", alignItems: "flex-end", paddingRight: "0.5rem"}}></i>
        case 'send_failed':
            return (
                <i className="fas fa-redo-alt"
                    onClick={() => props.resendMessage({question: props.message, timeSent: props.created_at, resend: true})}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                        paddingRight: "0.5rem",
                        color: "#ab0404",
                        cursor: "pointer"
                    }}
                />)
        default:
            return null
    }
}

export default BubbleRight