export const maritalStatuses = {
    status: "success",
    data: [
        {
            id: 1,
            name: "Single"
        },
        {
            id: 2,
            name: "Married"
        },
        {
            id: 3,
            name: "Divorced"
        },
        {
            id: 4,
            name: "Widow/Widower"
        }
    ]
}


export const titles = {
    status: "success",
    data: [
        {
            id: 1,
            name: "Doctor",
            abbreviation: "Dr"
        },
        {
            id: 2,
            name: "Architect",
            abbreviation: "Arch"
        },
        {
            id: 3,
            name: "Honorable",
            abbreviation: "Hon"
        },
        {
            id: 4,
            name: "Master",
            abbreviation: "Master"
        },
        {
            id: 5,
            name: "Mister",
            abbreviation: "Mr"
        },
        {
            id: 6,
            name: "Mistress",
            abbreviation: "Mrs"
        },
        {
            id: 7,
            name: "Miss",
            abbreviation: "Ms"
        },
        {
            id: 8,
            name: "Professor",
            abbreviation: "Prof"
        },
        {
            id: 9,
            name: "Reverend",
            abbreviation: "Rev"
        },
        {
            id: 10,
            name: "Alhaji",
            abbreviation: "Alh"
        },
        {
            id: 11,
            name: "Pastor",
            abbreviation: "Pst"
        },
        {
            id: 12,
            name: "Elder",
            abbreviation: "Eld"
        },
        {
            id: 13,
            name: "Deacon",
            abbreviation: "Dea"
        },
        {
            id: 14,
            name: "Deaconess",
            abbreviation: "Dss"
        },
        {
            id: 15,
            name: "Engineer",
            abbreviation: "Engr"
        },
        {
            id: 16,
            name: "Barrister",
            abbreviation: "Bar"
        }
    ]
};


export const preMeds = {
    status: "success",
    data: [
        {
            id: 1,
            name: "Acne"
        },
        {
            id: 2,
            name: "Acid reflux"
        },
        {
            id: 3,
            name: "Acromegaly"
        },
        {
            id: 4,
            name: "AIDS and HIV"
        },
        {
            id: 5,
            name: "Alcohol or drug abuse"
        },
        {
            id: 6,
            name: "Alzheimer's Disease"
        },
        {
            id: 7,
            name: "Amyotrophic Lateral Sclerosis"
        },
        {
            id: 8,
            name: "Anemia"
        },
        {
            id: 9,
            name: "Anorexia"
        },
        {
            id: 10,
            name: "Anxiety"
        },
        {
            id: 11,
            name: "Aortic or Mitral Valve Stenosis"
        },
        {
            id: 12,
            name: "Arteriosclerosis"
        },
        {
            id: 13,
            name: "Arteritis"
        },
        {
            id: 14,
            name: "Arthritis"
        },
        {
            id: 15,
            name: "Asbestosis"
        },
        {
            id: 16,
            name: "Asthma"
        },
        {
            id: 17,
            name: "Bipolar disease"
        },
        {
            id: 18,
            name: "Bulimia"
        },
        {
            id: 19,
            name: "Cancer"
        },
        {
            id: 20,
            name: "Cardiomyopathy"
        },
        {
            id: 21,
            name: "Celiac disease"
        },
        {
            id: 22,
            name: "Cerebral palsy"
        },
        {
            id: 23,
            name: "Chronic Obstructive Pulmonary Disease"
        },
        {
            id: 24,
            name: "Cirrhosis of the Liver"
        },
        {
            id: 25,
            name: "Coagulation Defects"
        },
        {
            id: 26,
            name: "Congestive Heart Failure"
        },
        {
            id: 27,
            name: "Coronary artery and heart disease, bypass surgery"
        },
        {
            id: 28,
            name: "Crohn's disease"
        },
        {
            id: 29,
            name: "C-section"
        },
        {
            id: 30,
            name: "Cystic Fibrosis"
        },
        {
            id: 31,
            name: "Demyelinating Disease"
        },
        {
            id: 32,
            name: "Depression"
        },
        {
            id: 33,
            name: "Dermatomyositis"
        },
        {
            id: 34,
            name: "Diabetes"
        },
        {
            id: 35,
            name: "Dialysis"
        },
        {
            id: 36,
            name: "Epilepsy"
        },
        {
            id: 37,
            name: "Esophageal Varicosities"
        },
        {
            id: 38,
            name: "Expectant parent (including fathers)"
        },
        {
            id: 39,
            name: "Friedreich's Ataxia"
        },
        {
            id: 40,
            name: "Heart burn"
        },
        {
            id: 41,
            name: "Hemophilia"
        },
        {
            id: 42,
            name: "Hepatitis"
        },
        {
            id: 43,
            name: "High cholesterol"
        },
        {
            id: 44,
            name: "Hysterectomy"
        },
        {
            id: 45,
            name: "Hypertension"
        },
        {
            id: 46,
            name: "Kidney disease(s)"
        },
        {
            id: 47,
            name: "Knee surgery"
        },
        {
            id: 48,
            name: "Lupus Erythematosus"
        },
        {
            id: 49,
            name: "Lyme disease"
        },
        {
            id: 50,
            name: "Menstrual irregularities"
        },
        {
            id: 51,
            name: "Mental disorders"
        },
        {
            id: 52,
            name: "Migraines"
        },
        {
            id: 53,
            name: "Multiple Sclerosis"
        },
        {
            id: 54,
            name: "Muscular dystrophy"
        },
        {
            id: 55,
            name: "Myasthenia Gravis"
        },
        {
            id: 56,
            name: "Narcolepsy"
        },
        {
            id: 57,
            name: "Obesity"
        },
        {
            id: 58,
            name: "Organ transplant"
        },
        {
            id: 59,
            name: "Pacemaker insertion"
        },
        {
            id: 60,
            name: "Paralysis"
        },
        {
            id: 61,
            name: "Paraplegia"
        },
        {
            id: 62,
            name: "Parkinson's Disease"
        },
        {
            id: 63,
            name: "Pending surgery or hospitalization"
        },
        {
            id: 64,
            name: "Pneumocystic pneumonia"
        },
        {
            id: 65,
            name: "Polycythemia Vera"
        },
        {
            id: 66,
            name: "Postpartum depression"
        },
        {
            id: 67,
            name: "Pregnancy"
        },
        {
            id: 68,
            name: "Psoriatic Arthritis"
        },
        {
            id: 69,
            name: "Pulmonary Fibrosis"
        },
        {
            id: 70,
            name: "Renal Failure"
        },
        {
            id: 71,
            name: "Sarcoidosis"
        },
        {
            id: 72,
            name: "Scleroderma"
        },
        {
            id: 73,
            name: "Seasonal affective disorder"
        },
        {
            id: 74,
            name: "Seizures"
        },
        {
            id: 75,
            name: "Sex reassignment"
        },
        {
            id: 76,
            name: "Sjogren's Syndrome"
        },
        {
            id: 77,
            name: "Sleep apnea"
        },
        {
            id: 78,
            name: "Stroke"
        },
        {
            id: 79,
            name: "Transsexualism"
        },
        {
            id: 80,
            name: "Tuberculosis"
        },
        {
            id: 81,
            name: "Ulcers"
        }
    ]
}

export const occupations = {
    status: "success",
    data: [
        {
            id: 1,
            name: "Accountant"
        },
        {
            id: 2,
            name: "Actor/Actress"
        },
        {
            id: 3,
            name: "Administrator"
        },
        {
            id: 4,
            name: "Agent"
        },
        {
            id: 5,
            name: "Agriculturist"
        },
        {
            id: 6,
            name: "Airline Steward/Stewardess"
        },
        {
            id: 7,
            name: "Architect"
        },
        {
            id: 8,
            name: "Armed Forces Member"
        },
        {
            id: 9,
            name: "Artisan"
        },
        {
            id: 10,
            name: "Artist"
        },
        {
            id: 11,
            name: "Author"
        },
        {
            id: 12,
            name: "Banker"
        },
        {
            id: 13,
            name: "Business man/woman"
        },
        {
            id: 14,
            name: "Chef"
        },
        {
            id: 15,
            name: "Civil Servant"
        },
        {
            id: 16,
            name: "Clerk"
        },
        {
            id: 17,
            name: "Company Executive"
        },
        {
            id: 18,
            name: "Cook"
        },
        {
            id: 19,
            name: "Correctional Officer"
        },
        {
            id: 20,
            name: "Counsellor"
        },
        {
            id: 21,
            name: "Decorator"
        },
        {
            id: 22,
            name: "Dentist"
        },
        {
            id: 23,
            name: "Designer"
        },
        {
            id: 24,
            name: "Diplomat"
        },
        {
            id: 25,
            name: "Doctor"
        },
        {
            id: 26,
            name: "Driver"
        },
        {
            id: 27,
            name: "Engineer"
        },
        {
            id: 28,
            name: "Finance Professional"
        },
        {
            id: 29,
            name: "Geologist"
        },
        {
            id: 30,
            name: "Hair Stylist"
        },
        {
            id: 31,
            name: "Health Worker"
        },
        {
            id: 32,
            name: "Imam"
        },
        {
            id: 33,
            name: "IT Professional"
        },
        {
            id: 34,
            name: "Journalist"
        },
        {
            id: 35,
            name: "Lawyer"
        },
        {
            id: 36,
            name: "Lecturer"
        },
        {
            id: 37,
            name: "Makeup Artist"
        },
        {
            id: 38,
            name: "Manager"
        },
        {
            id: 39,
            name: "Marketer"
        },
        {
            id: 40,
            name: "Media Professional"
        },
        {
            id: 41,
            name: "Musician"
        },
        {
            id: 42,
            name: "Nurse"
        },
        {
            id: 43,
            name: "Office Assistant"
        },
        {
            id: 44,
            name: "Office Worker"
        },
        {
            id: 45,
            name: "Optician"
        },
        {
            id: 46,
            name: "Other"
        },
        {
            id: 47,
            name: "Painter"
        },
        {
            id: 48,
            name: "Parliamentary Corps"
        },
        {
            id: 49,
            name: "Pastor"
        },
        {
            id: 50,
            name: "Personal Assistant"
        },
        {
            id: 51,
            name: "Pharmacist"
        },
        {
            id: 52,
            name: "Photographer"
        },
        {
            id: 53,
            name: "Physiotherapist"
        },
        {
            id: 54,
            name: "Pilot"
        },
        {
            id: 55,
            name: "Police Officer"
        },
        {
            id: 56,
            name: "Politician"
        },
        {
            id: 57,
            name: "Receptionist"
        },
        {
            id: 58,
            name: "Salesperson"
        },
        {
            id: 59,
            name: "Scientist"
        },
        {
            id: 60,
            name: "Secretary"
        },
        {
            id: 61,
            name: "Social Worker"
        },
        {
            id: 62,
            name: "Student"
        },
        {
            id: 63,
            name: "Tailor"
        },
        {
            id: 64,
            name: "Teacher"
        },
        {
            id: 65,
            name: "Technician/Technologist"
        },
        {
            id: 66,
            name: "Trader"
        },
        {
            id: 67,
            name: "Unemployed"
        },
        {
            id: 68,
            name: "Veterinarian"
        },
        {
            id: 69,
            name: "Waiter"
        },
        {
            id: 70,
            name: "Z- Classified"
        }
    ]
}

export const countries = {
    status: "success",
    data: [
        {
            id: 1,
            name: "Afghanistan",
            nationality: "Afghan"
        },
        {
            id: 2,
            name: "Albania",
            nationality: "Albanian"
        },
        {
            id: 3,
            name: "Algeria",
            nationality: "Algerian"
        },
        {
            id: 4,
            name: "American Samoa",
            nationality: ""
        },
        {
            id: 5,
            name: "Andorra",
            nationality: "Andorran"
        },
        {
            id: 6,
            name: "Angola",
            nationality: "Angolan"
        },
        {
            id: 7,
            name: "Anguilla",
            nationality: "Anguilla"
        },
        {
            id: 8,
            name: "Antigua and Barbuda",
            nationality: "Antiguan"
        },
        {
            id: 9,
            name: "Argentina",
            nationality: "Argentine"
        },
        {
            id: 10,
            name: "Armenia",
            nationality: "Armenian"
        },
        {
            id: 11,
            name: "Aruba",
            nationality: ""
        },
        {
            id: 12,
            name: "Australia",
            nationality: "Australian"
        },
        {
            id: 13,
            name: "Austria",
            nationality: ""
        },
        {
            id: 14,
            name: "Azerbaijan",
            nationality: ""
        },
        {
            id: 15,
            name: "Bahamas",
            nationality: ""
        },
        {
            id: 16,
            name: "Bahrain",
            nationality: ""
        },
        {
            id: 17,
            name: "Bangladesh",
            nationality: "Bangladeshi"
        },
        {
            id: 18,
            name: "Barbados",
            nationality: "Barbadian"
        },
        {
            id: 19,
            name: "Belarus",
            nationality: "Belarussian"
        },
        {
            id: 20,
            name: "Belgium",
            nationality: "Belgian"
        },
        {
            id: 21,
            name: "Belize",
            nationality: ""
        },
        {
            id: 22,
            name: "Benin",
            nationality: "Beninese"
        },
        {
            id: 23,
            name: "Bermuda",
            nationality: ""
        },
        {
            id: 24,
            name: "Bhutan",
            nationality: "Bhutanese"
        },
        {
            id: 25,
            name: "Bolivia",
            nationality: "Bolivian"
        },
        {
            id: 26,
            name: "Bosnia and Herzegovina",
            nationality: "Bosnian, Herzegovinian"
        },
        {
            id: 27,
            name: "Botswana",
            nationality: "Botswanian"
        },
        {
            id: 28,
            name: "Brazil",
            nationality: "Brazilian"
        },
        {
            id: 29,
            name: "Brunei Darussalam",
            nationality: "Bruneian"
        },
        {
            id: 30,
            name: "Bulgaria",
            nationality: "Bulgarian"
        },
        {
            id: 31,
            name: "Burkina Faso",
            nationality: "Burkinabe"
        },
        {
            id: 32,
            name: "Burundi",
            nationality: "Burundian"
        },
        {
            id: 33,
            name: "Cambodia",
            nationality: "Cambodian"
        },
        {
            id: 34,
            name: "Cameroon",
            nationality: "Cameroonian"
        },
        {
            id: 35,
            name: "Canada",
            nationality: "Canadian"
        },
        {
            id: 36,
            name: "Cape Verde",
            nationality: "Cape Verdean"
        },
        {
            id: 37,
            name: "Cayman Islands",
            nationality: ""
        },
        {
            id: 38,
            name: "Central African Republic",
            nationality: "Central African"
        },
        {
            id: 39,
            name: "Chad",
            nationality: "Chadian"
        },
        {
            id: 40,
            name: "Chile",
            nationality: "Chilean"
        },
        {
            id: 41,
            name: "China",
            nationality: "Chinese"
        },
        {
            id: 42,
            name: "Christmas Island",
            nationality: ""
        },
        {
            id: 43,
            name: "Cocos (Keeling) Islands",
            nationality: ""
        },
        {
            id: 44,
            name: "Colombia",
            nationality: "Colombian"
        },
        {
            id: 45,
            name: "Comoros",
            nationality: "Comoran"
        },
        {
            id: 46,
            name: "Congo (Brazzaville)",
            nationality: "Congolese"
        },
        {
            id: 47,
            name: "Congo (Kinshasa)",
            nationality: "Congolese"
        },
        {
            id: 48,
            name: "Cook Islands",
            nationality: ""
        },
        {
            id: 49,
            name: "Costa Rica",
            nationality: "Costa Rican"
        },
        {
            id: 50,
            name: "Côte D'Ivoire (Ivory Coast)",
            nationality: "Ivorian"
        },
        {
            id: 51,
            name: "Croatia (Hrvatska)",
            nationality: "Croat"
        },
        {
            id: 52,
            name: "Cuba",
            nationality: "Cuban"
        },
        {
            id: 53,
            name: "Cyprus",
            nationality: "Cypriot"
        },
        {
            id: 54,
            name: "Czech Republic",
            nationality: "Czech"
        },
        {
            id: 55,
            name: "Denmark",
            nationality: "Danish"
        },
        {
            id: 56,
            name: "Djibouti",
            nationality: "Djiboutian"
        },
        {
            id: 57,
            name: "Dominica",
            nationality: "Dominican (Dominica)"
        },
        {
            id: 58,
            name: "Dominican Republic",
            nationality: "Dominican"
        },
        {
            id: 59,
            name: "Ecuador",
            nationality: "Ecuadorian"
        },
        {
            id: 60,
            name: "Egypt",
            nationality: "Egyptian"
        },
        {
            id: 61,
            name: "El Salvador",
            nationality: "Salvadorian"
        },
        {
            id: 62,
            name: "Equatorial Guinea",
            nationality: "Equatoguinean"
        },
        {
            id: 63,
            name: "Eritrea",
            nationality: "Eritrean"
        },
        {
            id: 64,
            name: "Estonia",
            nationality: "Estonian"
        },
        {
            id: 65,
            name: "Ethiopia",
            nationality: "Ethiopian"
        },
        {
            id: 66,
            name: "Falkland Islands (Malvinas)",
            nationality: ""
        },
        {
            id: 67,
            name: "Faroe Islands",
            nationality: ""
        },
        {
            id: 68,
            name: "Fiji",
            nationality: "Fijian"
        },
        {
            id: 69,
            name: "Finland",
            nationality: "Finn"
        },
        {
            id: 70,
            name: "France",
            nationality: "French"
        },
        {
            id: 71,
            name: "French Guiana",
            nationality: ""
        },
        {
            id: 72,
            name: "French Polynesia",
            nationality: ""
        },
        {
            id: 73,
            name: "Gabon",
            nationality: "Gabonese"
        },
        {
            id: 74,
            name: "Gambia",
            nationality: "Gambian"
        },
        {
            id: 75,
            name: "Georgia",
            nationality: "Georgian"
        },
        {
            id: 76,
            name: "Germany",
            nationality: "German"
        },
        {
            id: 77,
            name: "Ghana",
            nationality: "Ghanaian"
        },
        {
            id: 78,
            name: "Gibraltar",
            nationality: ""
        },
        {
            id: 79,
            name: "Greece",
            nationality: "Greek"
        },
        {
            id: 80,
            name: "Greenland",
            nationality: ""
        },
        {
            id: 81,
            name: "Grenada",
            nationality: "Grenadian"
        },
        {
            id: 82,
            name: "Guadeloupe",
            nationality: ""
        },
        {
            id: 83,
            name: "Guam",
            nationality: ""
        },
        {
            id: 84,
            name: "Guatemala",
            nationality: "Guatemalan"
        },
        {
            id: 85,
            name: "Guinea",
            nationality: "Guinean"
        },
        {
            id: 86,
            name: "Guinea-Bissau",
            nationality: "Guinean (Guinea-Bissau)"
        },
        {
            id: 87,
            name: "Guyana",
            nationality: "Guyanese"
        },
        {
            id: 88,
            name: "Haiti",
            nationality: "Haitian"
        },
        {
            id: 89,
            name: "Holy See (Vatican City State)",
            nationality: ""
        },
        {
            id: 90,
            name: "Honduras",
            nationality: "Honduran"
        },
        {
            id: 91,
            name: "Hong Kong, SAR",
            nationality: ""
        },
        {
            id: 92,
            name: "Hungary",
            nationality: "Hungarian"
        },
        {
            id: 93,
            name: "Iceland",
            nationality: "Icelander"
        },
        {
            id: 94,
            name: "India",
            nationality: "Indian"
        },
        {
            id: 95,
            name: "Indonesia",
            nationality: "Indonesian"
        },
        {
            id: 96,
            name: "Iran, Islamic Republic of",
            nationality: "Iranian"
        },
        {
            id: 97,
            name: "Iraq",
            nationality: "Iraqi"
        },
        {
            id: 98,
            name: "Ireland",
            nationality: "Irish"
        },
        {
            id: 99,
            name: "Israel",
            nationality: "Israeli"
        },
        {
            id: 100,
            name: "Italy",
            nationality: "Italian"
        },
        {
            id: 101,
            name: "Jamaica",
            nationality: "Jamaican"
        },
        {
            id: 102,
            name: "Japan",
            nationality: "Japanese"
        },
        {
            id: 103,
            name: "Jordan",
            nationality: "Jordanian"
        },
        {
            id: 104,
            name: "Kazakhstan",
            nationality: "Kazakhstani"
        },
        {
            id: 105,
            name: "Kenya",
            nationality: "Kenyan"
        },
        {
            id: 106,
            name: "Kiribati",
            nationality: "I-Kiribati"
        },
        {
            id: 107,
            name: "Korea, Democratic People's Republic of (North)",
            nationality: "Korean (North)"
        },
        {
            id: 108,
            name: "Korea, Republic of (South)",
            nationality: "Korean (South)"
        },
        {
            id: 109,
            name: "Kuwait",
            nationality: "Kuwaiti"
        },
        {
            id: 110,
            name: "Kyrgyzstan",
            nationality: "Kyrgyzstani"
        },
        {
            id: 111,
            name: "Laos (Lao PDR)",
            nationality: "Laotian"
        },
        {
            id: 112,
            name: "Latvia",
            nationality: "Latvian"
        },
        {
            id: 113,
            name: "Lebanon",
            nationality: "Lebanese"
        },
        {
            id: 114,
            name: "Lesotho",
            nationality: "Mosotho"
        },
        {
            id: 115,
            name: "Liberia",
            nationality: "Liberian"
        },
        {
            id: 116,
            name: "Libya",
            nationality: "Libyan"
        },
        {
            id: 117,
            name: "Liechtenstein",
            nationality: ""
        },
        {
            id: 118,
            name: "Lithuania",
            nationality: "Lithuanian"
        },
        {
            id: 119,
            name: "Luxembourg",
            nationality: "Luxembourger"
        },
        {
            id: 120,
            name: "Macao (SAR China)",
            nationality: ""
        },
        {
            id: 121,
            name: "Macedonia, Republic of",
            nationality: "Macedonian"
        },
        {
            id: 122,
            name: "Madagascar",
            nationality: "Malagasy"
        },
        {
            id: 123,
            name: "Malawi",
            nationality: "Malawian"
        },
        {
            id: 124,
            name: "Malaysia",
            nationality: "Malaysian"
        },
        {
            id: 125,
            name: "Maldives",
            nationality: "Maldivian"
        },
        {
            id: 126,
            name: "Mali",
            nationality: "Malian"
        },
        {
            id: 127,
            name: "Malta",
            nationality: "Maltese"
        },
        {
            id: 128,
            name: "Marshall Islands",
            nationality: "Marshallese"
        },
        {
            id: 129,
            name: "Martinique",
            nationality: ""
        },
        {
            id: 130,
            name: "Mauritania",
            nationality: "Mauritanian"
        },
        {
            id: 131,
            name: "Mauritius",
            nationality: "Mauritian"
        },
        {
            id: 132,
            name: "Mayotte",
            nationality: ""
        },
        {
            id: 133,
            name: "Mexico",
            nationality: "Mexican"
        },
        {
            id: 134,
            name: "Micronesia, Federated States of",
            nationality: "Micronesian"
        },
        {
            id: 135,
            name: "Moldova",
            nationality: "Moldovan"
        },
        {
            id: 136,
            name: "Monaco",
            nationality: "Monegasque"
        },
        {
            id: 137,
            name: "Mongolia",
            nationality: "Mongolian"
        },
        {
            id: 138,
            name: "Montenegro",
            nationality: ""
        },
        {
            id: 139,
            name: "Montserrat",
            nationality: ""
        },
        {
            id: 140,
            name: "Morocco and Western Sahara",
            nationality: "Moroccan"
        },
        {
            id: 141,
            name: "Mozambique",
            nationality: "Mozambican"
        },
        {
            id: 142,
            name: "Myanmar",
            nationality: "Burmese"
        },
        {
            id: 143,
            name: "Namibia",
            nationality: "Namibian"
        },
        {
            id: 144,
            name: "Nauru",
            nationality: "Nauruan"
        },
        {
            id: 145,
            name: "Nepal",
            nationality: "Nepalese"
        },
        {
            id: 146,
            name: "Netherlands",
            nationality: "Dutch (Netherlands)"
        },
        {
            id: 147,
            name: "Netherlands Antilles",
            nationality: "Dutch"
        },
        {
            id: 148,
            name: "New Caledonia",
            nationality: ""
        },
        {
            id: 149,
            name: "New Zealand",
            nationality: "New Zealander"
        },
        {
            id: 150,
            name: "Nicaragua",
            nationality: "Nicaraguan"
        },
        {
            id: 151,
            name: "Niger",
            nationality: "Nigerien"
        },
        {
            id: 152,
            name: "Nigeria",
            nationality: "Nigerian"
        },
        {
            id: 153,
            name: "Niue",
            nationality: ""
        },
        {
            id: 154,
            name: "Norfolk Island",
            nationality: ""
        },
        {
            id: 155,
            name: "Northern Mariana Islands",
            nationality: ""
        },
        {
            id: 156,
            name: "Norway",
            nationality: "Norwegian"
        },
        {
            id: 157,
            name: "Oman",
            nationality: "Omani"
        },
        {
            id: 158,
            name: "Pakistan",
            nationality: "Pakistani"
        },
        {
            id: 159,
            name: "Palau",
            nationality: "Palauan"
        },
        {
            id: 160,
            name: "Palestine",
            nationality: "Palestinian"
        },
        {
            id: 161,
            name: "Panama",
            nationality: "Panamanian"
        },
        {
            id: 162,
            name: "Papua New Guinea",
            nationality: "Papua"
        },
        {
            id: 163,
            name: "Paraguay",
            nationality: "Paraguayan"
        },
        {
            id: 164,
            name: "Peru",
            nationality: "Peruvian"
        },
        {
            id: 165,
            name: "Philippines",
            nationality: "Filipino"
        },
        {
            id: 166,
            name: "Pitcairn",
            nationality: ""
        },
        {
            id: 167,
            name: "Poland",
            nationality: "Polish"
        },
        {
            id: 168,
            name: "Portugal",
            nationality: "Portuguese"
        },
        {
            id: 169,
            name: "Puerto Rico",
            nationality: "Puerto Rican"
        },
        {
            id: 170,
            name: "Qatar",
            nationality: "Qatari"
        },
        {
            id: 171,
            name: "Réunion and Mayotte",
            nationality: ""
        },
        {
            id: 172,
            name: "Romania",
            nationality: "Romanian"
        },
        {
            id: 173,
            name: "Russian Federation",
            nationality: "Russian"
        },
        {
            id: 174,
            name: "Rwanda",
            nationality: "Rwandan"
        },
        {
            id: 175,
            name: "Saint Helena and also Tristan Da Cunha",
            nationality: ""
        },
        {
            id: 176,
            name: "Saint Kitts and Nevis",
            nationality: "Kittian"
        },
        {
            id: 177,
            name: "Saint Lucia",
            nationality: "Saint Lucian"
        },
        {
            id: 178,
            name: "Saint Pierre and Miquelon",
            nationality: ""
        },
        {
            id: 179,
            name: "Saint Vincent and the Grenadines",
            nationality: "Saint Vincentian"
        },
        {
            id: 180,
            name: "Samoa",
            nationality: "Samoan"
        },
        {
            id: 181,
            name: "San Marino",
            nationality: "Sanmarinese"
        },
        {
            id: 182,
            name: "São Tomé and Principe",
            nationality: "São Tomean"
        },
        {
            id: 183,
            name: "Saudi Arabia",
            nationality: "Saudi"
        },
        {
            id: 184,
            name: "Senegal",
            nationality: "Senegalese"
        },
        {
            id: 185,
            name: "Serbia",
            nationality: "Serbian"
        },
        {
            id: 186,
            name: "Seychelles",
            nationality: "Seychellois"
        },
        {
            id: 187,
            name: "Sierra Leone",
            nationality: "Sierra Leonean"
        },
        {
            id: 188,
            name: "Singapore",
            nationality: "Singaporean"
        },
        {
            id: 189,
            name: "Slovakia",
            nationality: "Slovak"
        },
        {
            id: 190,
            name: "Slovenia",
            nationality: "Slovene"
        },
        {
            id: 191,
            name: "Solomon Islands",
            nationality: "Solomon Islander"
        },
        {
            id: 192,
            name: "Somalia",
            nationality: "Somali"
        },
        {
            id: 193,
            name: "South Africa",
            nationality: "South African"
        },
        {
            id: 194,
            name: "Spain",
            nationality: "Spanish"
        },
        {
            id: 195,
            name: "Sri Lanka",
            nationality: "Sri Lankan"
        },
        {
            id: 196,
            name: "Sudan",
            nationality: "Sudanese"
        },
        {
            id: 197,
            name: "Suriname",
            nationality: "Surinamer"
        },
        {
            id: 198,
            name: "Svalbard and Jan Mayen Islands",
            nationality: ""
        },
        {
            id: 199,
            name: "Swaziland",
            nationality: "Swazi"
        },
        {
            id: 200,
            name: "Sweden",
            nationality: "Swede"
        },
        {
            id: 201,
            name: "Switzerland",
            nationality: "Swiss"
        },
        {
            id: 202,
            name: "Syrian Arab Republic (Syria)",
            nationality: "Syrian"
        },
        {
            id: 203,
            name: "Taiwan, Republic of China",
            nationality: "Taiwanese"
        },
        {
            id: 204,
            name: "Tajikistan",
            nationality: "Tajikistani"
        },
        {
            id: 205,
            name: "Tanzania, United Republic of",
            nationality: "Tanzanian"
        },
        {
            id: 206,
            name: "Thailand",
            nationality: "Thai"
        },
        {
            id: 207,
            name: "Timor-Leste",
            nationality: ""
        },
        {
            id: 208,
            name: "Togo",
            nationality: "Togolese"
        },
        {
            id: 209,
            name: "Tokelau",
            nationality: ""
        },
        {
            id: 210,
            name: "Tonga",
            nationality: "Tongan"
        },
        {
            id: 211,
            name: "Trinidad and Tobago",
            nationality: "Trinidadian, Tobagonian"
        },
        {
            id: 212,
            name: "Tunisia",
            nationality: "Tunisian"
        },
        {
            id: 213,
            name: "Turkey",
            nationality: "Turkish"
        },
        {
            id: 214,
            name: "Turkmenistan",
            nationality: "Turkmen"
        },
        {
            id: 215,
            name: "Turks and Caicos Islands",
            nationality: ""
        },
        {
            id: 216,
            name: "Tuvalu",
            nationality: "Tuvaluan"
        },
        {
            id: 217,
            name: "Uganda",
            nationality: "Ugandan"
        },
        {
            id: 218,
            name: "Ukraine",
            nationality: "Ukrainian"
        },
        {
            id: 219,
            name: "United Arab Emirates",
            nationality: "Emirati"
        },
        {
            id: 220,
            name: "United Kingdom",
            nationality: "British"
        },
        {
            id: 221,
            name: "United States of America",
            nationality: "American"
        },
        {
            id: 222,
            name: "Uruguay",
            nationality: "Uruguayan"
        },
        {
            id: 223,
            name: "Uzbekistan",
            nationality: "Uzbekistani"
        },
        {
            id: 224,
            name: "Vanuatu",
            nationality: "Ni-Vanuatu"
        },
        {
            id: 225,
            name: "Venezuela (Bolivarian Republic of)",
            nationality: "Venezuelan"
        },
        {
            id: 226,
            name: "Vietnam",
            nationality: "Vietnamese"
        },
        {
            id: 227,
            name: "Virgin Islands, British",
            nationality: "British (Virgin Islands)"
        },
        {
            id: 228,
            name: "Virgin Islands, US",
            nationality: "American (Virgin Islands)"
        },
        {
            id: 229,
            name: "Wallis and Futuna Islands",
            nationality: ""
        },
        {
            id: 230,
            name: "Yemen",
            nationality: "Yemeni"
        },
        {
            id: 231,
            name: "Zambia",
            nationality: "Zambian"
        },
        {
            id: 232,
            name: "Zimbabwe",
            nationality: "Zimbabwean"
        }
    ]
}

export const states = {
    status: "success",
    data: [
        {
            id: 1,
            name: "Abia",
            country_id: 152,
            lgas: [
                {
                    id: 1,
                    name: "Aba North",
                    state_id: 1,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 2,
                    name: "Aba South",
                    state_id: 1,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 3,
                    name: "Arochukwu",
                    state_id: 1,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 4,
                    name: "Bende",
                    state_id: 1,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 5,
                    name: "Ikwuano",
                    state_id: 1,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 6,
                    name: "Isiala Ngwa North",
                    state_id: 1,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 7,
                    name: "Isiala Ngwa South",
                    state_id: 1,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 8,
                    name: "Isuikwuato",
                    state_id: 1,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 9,
                    name: "Obi Ngwa",
                    state_id: 1,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 10,
                    name: "Ohafia",
                    state_id: 1,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 11,
                    name: "Osisioma",
                    state_id: 1,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 12,
                    name: "Ugwunagbo",
                    state_id: 1,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 13,
                    name: "Ukwa East",
                    state_id: 1,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 14,
                    name: "Ukwa West",
                    state_id: 1,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 15,
                    name: "Umuahia North",
                    state_id: 1,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 16,
                    name: "Umuahia South",
                    state_id: 1,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 17,
                    name: "Umu Nneochi",
                    state_id: 1,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 2,
            name: "Adamawa",
            country_id: 152,
            lgas: [
                {
                    id: 18,
                    name: "Demsa",
                    state_id: 2,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 19,
                    name: "Fufure",
                    state_id: 2,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 20,
                    name: "Ganye",
                    state_id: 2,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 21,
                    name: "Gayuk",
                    state_id: 2,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 22,
                    name: "Gombi",
                    state_id: 2,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 23,
                    name: "Grie",
                    state_id: 2,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 24,
                    name: "Hong",
                    state_id: 2,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 25,
                    name: "Jada",
                    state_id: 2,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 26,
                    name: "Lamurde",
                    state_id: 2,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 27,
                    name: "Madagali",
                    state_id: 2,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 28,
                    name: "Maiha",
                    state_id: 2,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 29,
                    name: "Mayo Belwa",
                    state_id: 2,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 30,
                    name: "Michika",
                    state_id: 2,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 31,
                    name: "Mubi North",
                    state_id: 2,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 32,
                    name: "Mubi South",
                    state_id: 2,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 33,
                    name: "Numan",
                    state_id: 2,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 34,
                    name: "Shelleng",
                    state_id: 2,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 35,
                    name: "Song",
                    state_id: 2,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 36,
                    name: "Toungo",
                    state_id: 2,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 37,
                    name: "Yola North",
                    state_id: 2,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 38,
                    name: "Yola South",
                    state_id: 2,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 3,
            name: "Akwa Ibom",
            country_id: 152,
            lgas: [
                {
                    id: 39,
                    name: "Abak",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 40,
                    name: "Eastern Obolo",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 41,
                    name: "Eket",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 42,
                    name: "Esit Eket",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 43,
                    name: "Essien Udim",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 44,
                    name: "Etim Ekpo",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 45,
                    name: "Etinan",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 46,
                    name: "Ibeno",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 47,
                    name: "Ibesikpo Asutan",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 48,
                    name: "Ibiono-Ibom",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 49,
                    name: "Ika",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 50,
                    name: "Ikono",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 51,
                    name: "Ikot Abasi",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 52,
                    name: "Ikot Ekpene",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 53,
                    name: "Ini",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 54,
                    name: "Itu",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 55,
                    name: "Mbo",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 56,
                    name: "Mkpat-Enin",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 57,
                    name: "Nsit-Atai",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 58,
                    name: "Nsit-Ibom",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 59,
                    name: "Nsit-Ubium",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 60,
                    name: "Obot Akara",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 61,
                    name: "Okobo",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 62,
                    name: "Onna",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 63,
                    name: "Oron",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 64,
                    name: "Oruk Anam",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 65,
                    name: "Udung-Uko",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 66,
                    name: "Ukanafun",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 67,
                    name: "Uruan",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 68,
                    name: "Urue-Offong/Oruko",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 69,
                    name: "Uyo",
                    state_id: 3,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 4,
            name: "Anambra",
            country_id: 152,
            lgas: [
                {
                    id: 70,
                    name: "Aguata",
                    state_id: 4,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 71,
                    name: "Anambra East",
                    state_id: 4,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 72,
                    name: "Anambra West",
                    state_id: 4,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 73,
                    name: "Anaocha",
                    state_id: 4,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 74,
                    name: "Awka North",
                    state_id: 4,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 75,
                    name: "Awka South",
                    state_id: 4,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 76,
                    name: "Ayamelum",
                    state_id: 4,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 77,
                    name: "Dunukofia",
                    state_id: 4,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 78,
                    name: "Ekwusigo",
                    state_id: 4,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 79,
                    name: "Idemili North",
                    state_id: 4,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 80,
                    name: "Idemili South",
                    state_id: 4,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 81,
                    name: "Ihiala",
                    state_id: 4,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 82,
                    name: "Njikoka",
                    state_id: 4,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 83,
                    name: "Nnewi North",
                    state_id: 4,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 84,
                    name: "Nnewi South",
                    state_id: 4,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 85,
                    name: "Ogbaru",
                    state_id: 4,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 86,
                    name: "Onitsha North",
                    state_id: 4,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 87,
                    name: "Onitsha South",
                    state_id: 4,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 88,
                    name: "Orumba North",
                    state_id: 4,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 89,
                    name: "Orumba South",
                    state_id: 4,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 90,
                    name: "Oyi",
                    state_id: 4,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 5,
            name: "Bauchi",
            country_id: 152,
            lgas: [
                {
                    id: 91,
                    name: "Alkaleri",
                    state_id: 5,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 92,
                    name: "Bauchi",
                    state_id: 5,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 93,
                    name: "Bogoro",
                    state_id: 5,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 94,
                    name: "Damban",
                    state_id: 5,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 95,
                    name: "Darazo",
                    state_id: 5,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 96,
                    name: "Dass",
                    state_id: 5,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 97,
                    name: "Gamawa",
                    state_id: 5,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 98,
                    name: "Ganjuwa",
                    state_id: 5,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 99,
                    name: "Giade",
                    state_id: 5,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 100,
                    name: "Itas/Gadau",
                    state_id: 5,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 101,
                    name: "Jama'are",
                    state_id: 5,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 102,
                    name: "Katagum",
                    state_id: 5,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 103,
                    name: "Kirfi",
                    state_id: 5,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 104,
                    name: "Misau",
                    state_id: 5,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 105,
                    name: "Ningi",
                    state_id: 5,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 106,
                    name: "Shira",
                    state_id: 5,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 107,
                    name: "Tafawa Balewa",
                    state_id: 5,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 108,
                    name: "Toro",
                    state_id: 5,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 109,
                    name: "Warji",
                    state_id: 5,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 110,
                    name: "Zaki",
                    state_id: 5,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 6,
            name: "Bayelsa",
            country_id: 152,
            lgas: [
                {
                    id: 111,
                    name: "Brass",
                    state_id: 6,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 112,
                    name: "Ekeremor",
                    state_id: 6,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 113,
                    name: "Kolokuma/Opokuma",
                    state_id: 6,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 114,
                    name: "Nembe",
                    state_id: 6,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 115,
                    name: "Ogbia",
                    state_id: 6,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 116,
                    name: "Sagbama",
                    state_id: 6,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 117,
                    name: "Southern Ijaw",
                    state_id: 6,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 118,
                    name: "Yenagoa",
                    state_id: 6,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 7,
            name: "Benue",
            country_id: 152,
            lgas: [
                {
                    id: 119,
                    name: "Agatu",
                    state_id: 7,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 120,
                    name: "Apa",
                    state_id: 7,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 121,
                    name: "Ado",
                    state_id: 7,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 122,
                    name: "Buruku",
                    state_id: 7,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 123,
                    name: "Gboko",
                    state_id: 7,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 124,
                    name: "Guma",
                    state_id: 7,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 125,
                    name: "Gwer East",
                    state_id: 7,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 126,
                    name: "Gwer West",
                    state_id: 7,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 127,
                    name: "Katsina-Ala",
                    state_id: 7,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 128,
                    name: "Konshisha",
                    state_id: 7,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 129,
                    name: "Kwande",
                    state_id: 7,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 130,
                    name: "Logo",
                    state_id: 7,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 131,
                    name: "Makurdi",
                    state_id: 7,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 132,
                    name: "Obi",
                    state_id: 7,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 133,
                    name: "Ogbadibo",
                    state_id: 7,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 134,
                    name: "Ohimini",
                    state_id: 7,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 135,
                    name: "Oju",
                    state_id: 7,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 136,
                    name: "Okpokwu",
                    state_id: 7,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 137,
                    name: "Oturkpo",
                    state_id: 7,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 138,
                    name: "Tarka",
                    state_id: 7,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 139,
                    name: "Ukum",
                    state_id: 7,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 140,
                    name: "Ushongo",
                    state_id: 7,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 141,
                    name: "Vandeikya",
                    state_id: 7,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 8,
            name: "Borno",
            country_id: 152,
            lgas: [
                {
                    id: 142,
                    name: "Abadam",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 143,
                    name: "Askira/Uba",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 144,
                    name: "Bama",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 145,
                    name: "Bayo",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 146,
                    name: "Biu",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 147,
                    name: "Chibok",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 148,
                    name: "Damboa",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 149,
                    name: "Dikwa",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 150,
                    name: "Gubio",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 151,
                    name: "Guzamala",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 152,
                    name: "Gwoza",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 153,
                    name: "Hawul",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 154,
                    name: "Jere",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 155,
                    name: "Kaga",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 156,
                    name: "Kala/Balge",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 157,
                    name: "Konduga",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 158,
                    name: "Kukawa",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 159,
                    name: "Kwaya Kusar",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 160,
                    name: "Mafa",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 161,
                    name: "Magumeri",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 162,
                    name: "Maiduguri",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 163,
                    name: "Marte",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 164,
                    name: "Mobbar",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 165,
                    name: "Monguno",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 166,
                    name: "Ngala",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 167,
                    name: "Nganzai",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 168,
                    name: "Shani",
                    state_id: 8,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 9,
            name: "Cross River",
            country_id: 152,
            lgas: [
                {
                    id: 169,
                    name: "Abi",
                    state_id: 9,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 170,
                    name: "Akamkpa",
                    state_id: 9,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 171,
                    name: "Akpabuyo",
                    state_id: 9,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 172,
                    name: "Bakassi",
                    state_id: 9,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 173,
                    name: "Bekwarra",
                    state_id: 9,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 174,
                    name: "Biase",
                    state_id: 9,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 175,
                    name: "Boki",
                    state_id: 9,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 176,
                    name: "Calabar Municipal",
                    state_id: 9,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 177,
                    name: "Calabar South",
                    state_id: 9,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 178,
                    name: "Etung",
                    state_id: 9,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 179,
                    name: "Ikom",
                    state_id: 9,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 180,
                    name: "Obanliku",
                    state_id: 9,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 181,
                    name: "Obubra",
                    state_id: 9,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 182,
                    name: "Obudu",
                    state_id: 9,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 183,
                    name: "Odukpani",
                    state_id: 9,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 184,
                    name: "Ogoja",
                    state_id: 9,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 185,
                    name: "Yakuur",
                    state_id: 9,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 186,
                    name: "Yala",
                    state_id: 9,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 10,
            name: "Delta",
            country_id: 152,
            lgas: [
                {
                    id: 187,
                    name: "Aniocha North",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 188,
                    name: "Aniocha South",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 189,
                    name: "Bomadi",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 190,
                    name: "Burutu",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 191,
                    name: "Ethiope East",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 192,
                    name: "Ethiope West",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 193,
                    name: "Ika North East",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 194,
                    name: "Ika South",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 195,
                    name: "Isoko North",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 196,
                    name: "Isoko South",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 197,
                    name: "Ndokwa East",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 198,
                    name: "Ndokwa West",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 199,
                    name: "Okpe",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 200,
                    name: "Oshimili North",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 201,
                    name: "Oshimili South",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 202,
                    name: "Patani",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 203,
                    name: "Sapele",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 204,
                    name: "Udu",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 205,
                    name: "Ughelli North",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 206,
                    name: "Ughelli South",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 207,
                    name: "Ukwuani",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 208,
                    name: "Uvwie",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 209,
                    name: "Warri North",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 210,
                    name: "Warri South",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 211,
                    name: "Warri South West",
                    state_id: 10,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 11,
            name: "Ebonyi",
            country_id: 152,
            lgas: [
                {
                    id: 212,
                    name: "Abakaliki",
                    state_id: 11,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 213,
                    name: "Afikpo North",
                    state_id: 11,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 214,
                    name: "Afikpo South (Edda)",
                    state_id: 11,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 215,
                    name: "Ebonyi",
                    state_id: 11,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 216,
                    name: "Ezza North",
                    state_id: 11,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 217,
                    name: "Ezza South",
                    state_id: 11,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 218,
                    name: "Ikwo",
                    state_id: 11,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 219,
                    name: "Ishielu",
                    state_id: 11,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 220,
                    name: "Ivo",
                    state_id: 11,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 221,
                    name: "Izzi",
                    state_id: 11,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 222,
                    name: "Ohaozara",
                    state_id: 11,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 223,
                    name: "Ohaukwu",
                    state_id: 11,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 224,
                    name: "Onicha",
                    state_id: 11,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 12,
            name: "Edo",
            country_id: 152,
            lgas: [
                {
                    id: 225,
                    name: "Akoko-Edo",
                    state_id: 12,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 226,
                    name: "Egor",
                    state_id: 12,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 227,
                    name: "Esan Central",
                    state_id: 12,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 228,
                    name: "Esan North-East",
                    state_id: 12,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 229,
                    name: "Esan South-East",
                    state_id: 12,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 230,
                    name: "Esan West",
                    state_id: 12,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 231,
                    name: "Etsako Central",
                    state_id: 12,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 232,
                    name: "Etsako East",
                    state_id: 12,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 233,
                    name: "Etsako West",
                    state_id: 12,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 234,
                    name: "Igueben",
                    state_id: 12,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 235,
                    name: "Ikpoba Okha",
                    state_id: 12,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 236,
                    name: "Orhionmwon",
                    state_id: 12,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 237,
                    name: "Oredo",
                    state_id: 12,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 238,
                    name: "Ovia North-East",
                    state_id: 12,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 239,
                    name: "Ovia South-West",
                    state_id: 12,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 240,
                    name: "Owan East",
                    state_id: 12,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 241,
                    name: "Owan West",
                    state_id: 12,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 242,
                    name: "Uhunmwonde",
                    state_id: 12,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 13,
            name: "Ekiti",
            country_id: 152,
            lgas: [
                {
                    id: 243,
                    name: "Ado Ekiti",
                    state_id: 13,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 244,
                    name: "Efon",
                    state_id: 13,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 245,
                    name: "Ekiti East",
                    state_id: 13,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 246,
                    name: "Ekiti South-West",
                    state_id: 13,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 247,
                    name: "Ekiti West",
                    state_id: 13,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 248,
                    name: "Emure",
                    state_id: 13,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 249,
                    name: "Gbonyin",
                    state_id: 13,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 250,
                    name: "Ido Osi",
                    state_id: 13,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 251,
                    name: "Ijero",
                    state_id: 13,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 252,
                    name: "Ikere",
                    state_id: 13,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 253,
                    name: "Ikole",
                    state_id: 13,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 254,
                    name: "Ilejemeje",
                    state_id: 13,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 255,
                    name: "Irepodun/Ifelodun",
                    state_id: 13,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 256,
                    name: "Ise/Orun",
                    state_id: 13,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 257,
                    name: "Moba",
                    state_id: 13,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 258,
                    name: "Oye",
                    state_id: 13,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 14,
            name: "Enugu",
            country_id: 152,
            lgas: [
                {
                    id: 259,
                    name: "Aninri",
                    state_id: 14,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 260,
                    name: "Awgu",
                    state_id: 14,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 261,
                    name: "Enugu East",
                    state_id: 14,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 262,
                    name: "Enugu North",
                    state_id: 14,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 263,
                    name: "Enugu South",
                    state_id: 14,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 264,
                    name: "Ezeagu",
                    state_id: 14,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 265,
                    name: "Igbo Etiti",
                    state_id: 14,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 266,
                    name: "Igbo Eze North",
                    state_id: 14,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 267,
                    name: "Igbo Eze South",
                    state_id: 14,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 268,
                    name: "Isi Uzo",
                    state_id: 14,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 269,
                    name: "Nkanu East",
                    state_id: 14,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 270,
                    name: "Nkanu West",
                    state_id: 14,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 271,
                    name: "Nsukka",
                    state_id: 14,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 272,
                    name: "Oji River",
                    state_id: 14,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 273,
                    name: "Udenu",
                    state_id: 14,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 274,
                    name: "Udi",
                    state_id: 14,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 275,
                    name: "Uzo-Uwani",
                    state_id: 14,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 15,
            name: "Federal Capital Territory (FCT)",
            country_id: 152,
            lgas: [
                {
                    id: 276,
                    name: "Abaji",
                    state_id: 15,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 277,
                    name: "Bwari",
                    state_id: 15,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 278,
                    name: "Gwagwalada",
                    state_id: 15,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 279,
                    name: "Kuje",
                    state_id: 15,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 280,
                    name: "Kwali",
                    state_id: 15,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 281,
                    name: "Municipal Area Council",
                    state_id: 15,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 16,
            name: "Gombe",
            country_id: 152,
            lgas: [
                {
                    id: 282,
                    name: "Akko",
                    state_id: 16,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 283,
                    name: "Balanga",
                    state_id: 16,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 284,
                    name: "Billiri",
                    state_id: 16,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 285,
                    name: "Dukku",
                    state_id: 16,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 286,
                    name: "Funakaye",
                    state_id: 16,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 287,
                    name: "Gombe",
                    state_id: 16,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 288,
                    name: "Kaltungo",
                    state_id: 16,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 289,
                    name: "Kwami",
                    state_id: 16,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 290,
                    name: "Nafada",
                    state_id: 16,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 291,
                    name: "Shongom",
                    state_id: 16,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 292,
                    name: "Yamaltu/Deba",
                    state_id: 16,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 17,
            name: "Imo",
            country_id: 152,
            lgas: [
                {
                    id: 293,
                    name: "Aboh Mbaise",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 294,
                    name: "Ahiazu Mbaise",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 295,
                    name: "Ehime Mbano",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 296,
                    name: "Ezinihitte",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 297,
                    name: "Ideato North",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 298,
                    name: "Ideato South",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 299,
                    name: "Ihitte/Uboma",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 300,
                    name: "Ikeduru",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 301,
                    name: "Isiala Mbano",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 302,
                    name: "Isu",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 303,
                    name: "Mbaitoli",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 304,
                    name: "Ngor Okpala",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 305,
                    name: "Njaba",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 306,
                    name: "Nkwerre",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 307,
                    name: "Nwangele",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 308,
                    name: "Obowo",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 309,
                    name: "Oguta",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 310,
                    name: "Ohaji/Egbema",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 311,
                    name: "Okigwe",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 312,
                    name: "Orlu",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 313,
                    name: "Orsu",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 314,
                    name: "Oru East",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 315,
                    name: "Oru West",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 316,
                    name: "Owerri Municipal",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 317,
                    name: "Owerri North",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 318,
                    name: "Owerri West",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 319,
                    name: "Unuimo",
                    state_id: 17,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 18,
            name: "Jigawa",
            country_id: 152,
            lgas: [
                {
                    id: 320,
                    name: "Auyo",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 321,
                    name: "Babura",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 322,
                    name: "Biriniwa",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 323,
                    name: "Birnin Kudu",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 324,
                    name: "Buji",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 325,
                    name: "Dutse",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 326,
                    name: "Gagarawa",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 327,
                    name: "Garki",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 328,
                    name: "Gumel",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 329,
                    name: "Guri",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 330,
                    name: "Gwaram",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 331,
                    name: "Gwiwa",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 332,
                    name: "Hadejia",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 333,
                    name: "Jahun",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 334,
                    name: "Kafin Hausa",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 335,
                    name: "Kazaure",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 336,
                    name: "Kiri Kasama",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 337,
                    name: "Kiyawa",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 338,
                    name: "Kaugama",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 339,
                    name: "Maigatari",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 340,
                    name: "Malam Madori",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 341,
                    name: "Miga",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 342,
                    name: "Ringim",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 343,
                    name: "Roni",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 344,
                    name: "Sule Tankarkar",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 345,
                    name: "Taura",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 346,
                    name: "Yankwashi",
                    state_id: 18,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 19,
            name: "Kaduna",
            country_id: 152,
            lgas: [
                {
                    id: 347,
                    name: "Birnin Gwari",
                    state_id: 19,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 348,
                    name: "Chikun",
                    state_id: 19,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 349,
                    name: "Giwa",
                    state_id: 19,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 350,
                    name: "Igabi",
                    state_id: 19,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 351,
                    name: "Ikara",
                    state_id: 19,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 352,
                    name: "Jaba",
                    state_id: 19,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 353,
                    name: "Jema'a",
                    state_id: 19,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 354,
                    name: "Kachia",
                    state_id: 19,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 355,
                    name: "Kaduna North",
                    state_id: 19,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 356,
                    name: "Kaduna South",
                    state_id: 19,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 357,
                    name: "Kagarko",
                    state_id: 19,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 358,
                    name: "Kajuru",
                    state_id: 19,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 359,
                    name: "Kaura",
                    state_id: 19,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 360,
                    name: "Kubau",
                    state_id: 19,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 361,
                    name: "Kudan",
                    state_id: 19,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 362,
                    name: "Lere",
                    state_id: 19,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 363,
                    name: "Makarfi",
                    state_id: 19,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 364,
                    name: "Sabon Gari",
                    state_id: 19,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 365,
                    name: "Sanga",
                    state_id: 19,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 366,
                    name: "Soba",
                    state_id: 19,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 367,
                    name: "Zangon Kataf",
                    state_id: 19,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 368,
                    name: "Zaria",
                    state_id: 19,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 20,
            name: "Kano",
            country_id: 152,
            lgas: [
                {
                    id: 369,
                    name: "Ajingi",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 370,
                    name: "Albasu",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 371,
                    name: "Bagwai",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 372,
                    name: "Bebeji",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 373,
                    name: "Bichi",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 374,
                    name: "Bunkure",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 375,
                    name: "Dala",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 376,
                    name: "Dambatta",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 377,
                    name: "Dawakin Kudu",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 378,
                    name: "Dawakin Tofa",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 379,
                    name: "Doguwa",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 380,
                    name: "Fagge",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 381,
                    name: "Gabasawa",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 382,
                    name: "Garko",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 383,
                    name: "Garun Mallam",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 384,
                    name: "Gaya",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 385,
                    name: "Gezawa",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 386,
                    name: "Gwale",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 387,
                    name: "Gwarzo",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 388,
                    name: "Kabo",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 389,
                    name: "Kano Municipal",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 390,
                    name: "Karaye",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 391,
                    name: "Kibiya",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 392,
                    name: "Kiru",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 393,
                    name: "Kumbotso",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 394,
                    name: "Kunchi",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 395,
                    name: "Kura",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 396,
                    name: "Madobi",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 397,
                    name: "Makoda",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 398,
                    name: "Minjibir",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 399,
                    name: "Nasarawa",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 400,
                    name: "Rano",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 401,
                    name: "Rimin Gado",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 402,
                    name: "Rogo",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 403,
                    name: "Shanono",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 404,
                    name: "Sumaila",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 405,
                    name: "Takai",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 406,
                    name: "Tarauni",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 407,
                    name: "Tofa",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 408,
                    name: "Tsanyawa",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 409,
                    name: "Tudun Wada",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 410,
                    name: "Ungogo",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 411,
                    name: "Warawa",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 412,
                    name: "Wudil",
                    state_id: 20,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 21,
            name: "Katsina",
            country_id: 152,
            lgas: [
                {
                    id: 413,
                    name: "Bakori",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 414,
                    name: "Batagarawa",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 415,
                    name: "Batsari",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 416,
                    name: "Baure",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 417,
                    name: "Bindawa",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 418,
                    name: "Charanchi",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 419,
                    name: "Dandume",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 420,
                    name: "Danja",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 421,
                    name: "Dan Musa",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 422,
                    name: "Daura",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 423,
                    name: "Dutsi",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 424,
                    name: "Dutsin Ma",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 425,
                    name: "Faskari",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 426,
                    name: "Funtua",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 427,
                    name: "Ingawa",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 428,
                    name: "Jibia",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 429,
                    name: "Kafur",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 430,
                    name: "Kaita",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 431,
                    name: "Kankara",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 432,
                    name: "Kankia",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 433,
                    name: "Katsina",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 434,
                    name: "Kurfi",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 435,
                    name: "Kusada",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 436,
                    name: "Mai'Adua",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 437,
                    name: "Malumfashi",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 438,
                    name: "Mani",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 439,
                    name: "Mashi",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 440,
                    name: "Matazu",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 441,
                    name: "Musawa",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 442,
                    name: "Rimi",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 443,
                    name: "Sabuwa",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 444,
                    name: "Safana",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 445,
                    name: "Sandamu",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 446,
                    name: "Zango",
                    state_id: 21,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 22,
            name: "Kebbi",
            country_id: 152,
            lgas: [
                {
                    id: 447,
                    name: "Aleiro",
                    state_id: 22,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 448,
                    name: "Arewa Dandi",
                    state_id: 22,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 449,
                    name: "Argungu",
                    state_id: 22,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 450,
                    name: "Augie",
                    state_id: 22,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 451,
                    name: "Bagudo",
                    state_id: 22,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 452,
                    name: "Birnin Kebbi",
                    state_id: 22,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 453,
                    name: "Bunza",
                    state_id: 22,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 454,
                    name: "Dandi",
                    state_id: 22,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 455,
                    name: "Fakai",
                    state_id: 22,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 456,
                    name: "Gwandu",
                    state_id: 22,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 457,
                    name: "Jega",
                    state_id: 22,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 458,
                    name: "Kalgo",
                    state_id: 22,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 459,
                    name: "Koko/Besse",
                    state_id: 22,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 460,
                    name: "Maiyama",
                    state_id: 22,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 461,
                    name: "Ngaski",
                    state_id: 22,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 462,
                    name: "Sakaba",
                    state_id: 22,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 463,
                    name: "Shanga",
                    state_id: 22,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 464,
                    name: "Suru",
                    state_id: 22,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 465,
                    name: "Wasagu/Danko",
                    state_id: 22,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 466,
                    name: "Yauri",
                    state_id: 22,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 467,
                    name: "Zuru",
                    state_id: 22,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 23,
            name: "Kogi",
            country_id: 152,
            lgas: [
                {
                    id: 468,
                    name: "Adavi",
                    state_id: 23,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 469,
                    name: "Ajaokuta",
                    state_id: 23,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 470,
                    name: "Ankpa",
                    state_id: 23,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 471,
                    name: "Bassa",
                    state_id: 23,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 472,
                    name: "Dekina",
                    state_id: 23,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 473,
                    name: "Ibaji",
                    state_id: 23,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 474,
                    name: "Idah",
                    state_id: 23,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 475,
                    name: "Igalamela Odolu",
                    state_id: 23,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 476,
                    name: "Ijumu",
                    state_id: 23,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 477,
                    name: "Kabba/Bunu",
                    state_id: 23,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 478,
                    name: "Kogi",
                    state_id: 23,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 479,
                    name: "Lokoja",
                    state_id: 23,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 480,
                    name: "Mopa Muro",
                    state_id: 23,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 481,
                    name: "Ofu",
                    state_id: 23,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 482,
                    name: "Ogori/Magongo",
                    state_id: 23,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 483,
                    name: "Okehi",
                    state_id: 23,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 484,
                    name: "Okene",
                    state_id: 23,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 485,
                    name: "Olamaboro",
                    state_id: 23,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 486,
                    name: "Omala",
                    state_id: 23,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 487,
                    name: "Yagba East",
                    state_id: 23,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 488,
                    name: "Yagba West",
                    state_id: 23,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 24,
            name: "Kwara",
            country_id: 152,
            lgas: [
                {
                    id: 489,
                    name: "Asa",
                    state_id: 24,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 490,
                    name: "Baruten",
                    state_id: 24,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 491,
                    name: "Edu",
                    state_id: 24,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 492,
                    name: "Ekiti",
                    state_id: 24,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 493,
                    name: "Ifelodun",
                    state_id: 24,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 494,
                    name: "Ilorin East",
                    state_id: 24,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 495,
                    name: "Ilorin South",
                    state_id: 24,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 496,
                    name: "Ilorin West",
                    state_id: 24,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 497,
                    name: "Irepodun",
                    state_id: 24,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 498,
                    name: "Isin",
                    state_id: 24,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 499,
                    name: "Kaiama",
                    state_id: 24,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 500,
                    name: "Moro",
                    state_id: 24,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 501,
                    name: "Offa",
                    state_id: 24,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 502,
                    name: "Oke Ero",
                    state_id: 24,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 503,
                    name: "Oyun",
                    state_id: 24,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 504,
                    name: "Pategi",
                    state_id: 24,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 25,
            name: "Lagos",
            country_id: 152,
            lgas: [
                {
                    id: 505,
                    name: "Agege",
                    state_id: 25,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 506,
                    name: "Ajeromi-Ifelodun",
                    state_id: 25,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 507,
                    name: "Alimosho",
                    state_id: 25,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 508,
                    name: "Amuwo-Odofin",
                    state_id: 25,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 509,
                    name: "Apapa",
                    state_id: 25,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 510,
                    name: "Badagry",
                    state_id: 25,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 511,
                    name: "Epe",
                    state_id: 25,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 512,
                    name: "Eti Osa",
                    state_id: 25,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 513,
                    name: "Ibeju-Lekki",
                    state_id: 25,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 514,
                    name: "Ifako-Ijaiye",
                    state_id: 25,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 515,
                    name: "Ikeja",
                    state_id: 25,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 516,
                    name: "Ikorodu",
                    state_id: 25,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 517,
                    name: "Kosofe",
                    state_id: 25,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 518,
                    name: "Lagos Island",
                    state_id: 25,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 519,
                    name: "Lagos Mainland",
                    state_id: 25,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 520,
                    name: "Mushin",
                    state_id: 25,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 521,
                    name: "Ojo",
                    state_id: 25,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 522,
                    name: "Oshodi-Isolo",
                    state_id: 25,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 523,
                    name: "Shomolu",
                    state_id: 25,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 524,
                    name: "Surulere",
                    state_id: 25,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 26,
            name: "Nasarawa",
            country_id: 152,
            lgas: [
                {
                    id: 525,
                    name: "Akwanga",
                    state_id: 26,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 526,
                    name: "Awe",
                    state_id: 26,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 527,
                    name: "Doma",
                    state_id: 26,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 528,
                    name: "Karu",
                    state_id: 26,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 529,
                    name: "Keana",
                    state_id: 26,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 530,
                    name: "Keffi",
                    state_id: 26,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 531,
                    name: "Kokona",
                    state_id: 26,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 532,
                    name: "Lafia",
                    state_id: 26,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 533,
                    name: "Nasarawa",
                    state_id: 26,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 534,
                    name: "Nasarawa Egon",
                    state_id: 26,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 535,
                    name: "Obi",
                    state_id: 26,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 536,
                    name: "Toto",
                    state_id: 26,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 537,
                    name: "Wamba",
                    state_id: 26,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 27,
            name: "Niger",
            country_id: 152,
            lgas: [
                {
                    id: 538,
                    name: "Agaie",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 539,
                    name: "Agwara",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 540,
                    name: "Bida",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 541,
                    name: "Borgu",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 542,
                    name: "Bosso",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 543,
                    name: "Chanchaga",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 544,
                    name: "Edati",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 545,
                    name: "Gbako",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 546,
                    name: "Gurara",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 547,
                    name: "Katcha",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 548,
                    name: "Kontagora",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 549,
                    name: "Lapai",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 550,
                    name: "Lavun",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 551,
                    name: "Magama",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 552,
                    name: "Mariga",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 553,
                    name: "Mashegu",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 554,
                    name: "Mokwa",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 555,
                    name: "Moya",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 556,
                    name: "Paikoro",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 557,
                    name: "Rafi",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 558,
                    name: "Rijau",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 559,
                    name: "Shiroro",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 560,
                    name: "Suleja",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 561,
                    name: "Tafa",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 562,
                    name: "Wushishi",
                    state_id: 27,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 28,
            name: "Ogun",
            country_id: 152,
            lgas: [
                {
                    id: 563,
                    name: "Abeokuta North",
                    state_id: 28,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 564,
                    name: "Abeokuta South",
                    state_id: 28,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 565,
                    name: "Ado-Odo/Ota",
                    state_id: 28,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 566,
                    name: "Yewa North",
                    state_id: 28,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 567,
                    name: "Yewa South",
                    state_id: 28,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 568,
                    name: "Ewekoro",
                    state_id: 28,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 569,
                    name: "Ifo",
                    state_id: 28,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 570,
                    name: "Ijebu East",
                    state_id: 28,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 571,
                    name: "Ijebu North",
                    state_id: 28,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 572,
                    name: "Ijebu North East",
                    state_id: 28,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 573,
                    name: "Ijebu Ode",
                    state_id: 28,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 574,
                    name: "Ikenne",
                    state_id: 28,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 575,
                    name: "Imeko Afon",
                    state_id: 28,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 576,
                    name: "Ipokia",
                    state_id: 28,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 577,
                    name: "Obafemi Owode",
                    state_id: 28,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 578,
                    name: "Odeda",
                    state_id: 28,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 579,
                    name: "Odogbolu",
                    state_id: 28,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 580,
                    name: "Ogun Waterside",
                    state_id: 28,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 581,
                    name: "Remo North",
                    state_id: 28,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 582,
                    name: "Shagamu",
                    state_id: 28,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 29,
            name: "Ondo",
            country_id: 152,
            lgas: [
                {
                    id: 583,
                    name: "Akoko North-East",
                    state_id: 29,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 584,
                    name: "Akoko North-West",
                    state_id: 29,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 585,
                    name: "Akoko South-West",
                    state_id: 29,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 586,
                    name: "Akoko South-East",
                    state_id: 29,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 587,
                    name: "Akure North",
                    state_id: 29,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 588,
                    name: "Akure South",
                    state_id: 29,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 589,
                    name: "Ese Odo",
                    state_id: 29,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 590,
                    name: "Idanre",
                    state_id: 29,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 591,
                    name: "Ifedore",
                    state_id: 29,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 592,
                    name: "Ilaje",
                    state_id: 29,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 593,
                    name: "Ile Oluji/Okeigbo",
                    state_id: 29,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 594,
                    name: "Irele",
                    state_id: 29,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 595,
                    name: "Odigbo",
                    state_id: 29,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 596,
                    name: "Okitipupa",
                    state_id: 29,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 597,
                    name: "Ondo East",
                    state_id: 29,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 598,
                    name: "Ondo West",
                    state_id: 29,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 599,
                    name: "Ose",
                    state_id: 29,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 600,
                    name: "Owo",
                    state_id: 29,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 30,
            name: "Osun",
            country_id: 152,
            lgas: [
                {
                    id: 601,
                    name: "Atakunmosa East",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 602,
                    name: "Atakunmosa West",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 603,
                    name: "Aiyedaade",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 604,
                    name: "Aiyedire",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 605,
                    name: "Boluwaduro",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 606,
                    name: "Boripe",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 607,
                    name: "Ede North",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 608,
                    name: "Ede South",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 609,
                    name: "Ife Central",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 610,
                    name: "Ife East",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 611,
                    name: "Ife North",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 612,
                    name: "Ife South",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 613,
                    name: "Egbedore",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 614,
                    name: "Ejigbo",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 615,
                    name: "Ifedayo",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 616,
                    name: "Ifelodun",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 617,
                    name: "Ila",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 618,
                    name: "Ilesa East",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 619,
                    name: "Ilesa West",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 620,
                    name: "Irepodun",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 621,
                    name: "Irewole",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 622,
                    name: "Isokan",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 623,
                    name: "Iwo",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 624,
                    name: "Obokun",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 625,
                    name: "Odo Otin",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 626,
                    name: "Ola Oluwa",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 627,
                    name: "Olorunda",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 628,
                    name: "Oriade",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 629,
                    name: "Orolu",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 630,
                    name: "Osogbo",
                    state_id: 30,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 31,
            name: "Oyo",
            country_id: 152,
            lgas: [
                {
                    id: 631,
                    name: "Afijio",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 632,
                    name: "Akinyele",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 633,
                    name: "Atiba",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 634,
                    name: "Atisbo",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 635,
                    name: "Egbeda",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 636,
                    name: "Ibadan North",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 637,
                    name: "Ibadan North-East",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 638,
                    name: "Ibadan North-West",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 639,
                    name: "Ibadan South-East",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 640,
                    name: "Ibadan South-West",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 641,
                    name: "Ibarapa Central",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 642,
                    name: "Ibarapa East",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 643,
                    name: "Ibarapa North",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 644,
                    name: "Ido",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 645,
                    name: "Irepo",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 646,
                    name: "Iseyin",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 647,
                    name: "Itesiwaju",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 648,
                    name: "Iwajowa",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 649,
                    name: "Kajola",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 650,
                    name: "Lagelu",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 651,
                    name: "Ogbomosho North",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 652,
                    name: "Ogbomosho South",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 653,
                    name: "Ogo Oluwa",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 654,
                    name: "Olorunsogo",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 655,
                    name: "Oluyole",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 656,
                    name: "Ona Ara",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 657,
                    name: "Orelope",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 658,
                    name: "Ori Ire",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 659,
                    name: "Oyo",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 660,
                    name: "Oyo East",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 661,
                    name: "Saki East",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 662,
                    name: "Saki West",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 663,
                    name: "Surulere",
                    state_id: 31,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 32,
            name: "Plateau",
            country_id: 152,
            lgas: [
                {
                    id: 664,
                    name: "Bokkos",
                    state_id: 32,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 665,
                    name: "Barkin Ladi",
                    state_id: 32,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 666,
                    name: "Bassa",
                    state_id: 32,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 667,
                    name: "Jos East",
                    state_id: 32,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 668,
                    name: "Jos North",
                    state_id: 32,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 669,
                    name: "Jos South",
                    state_id: 32,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 670,
                    name: "Kanam",
                    state_id: 32,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 671,
                    name: "Kanke",
                    state_id: 32,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 672,
                    name: "Langtang South",
                    state_id: 32,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 673,
                    name: "Langtang North",
                    state_id: 32,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 674,
                    name: "Mangu",
                    state_id: 32,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 675,
                    name: "Mikang",
                    state_id: 32,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 676,
                    name: "Pankshin",
                    state_id: 32,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 677,
                    name: "Qua'an Pan",
                    state_id: 32,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 678,
                    name: "Riyom",
                    state_id: 32,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 679,
                    name: "Shendam",
                    state_id: 32,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 680,
                    name: "Wase",
                    state_id: 32,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 33,
            name: "Rivers",
            country_id: 152,
            lgas: [
                {
                    id: 681,
                    name: "Abua/Odual",
                    state_id: 33,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 682,
                    name: "Ahoada East",
                    state_id: 33,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 683,
                    name: "Ahoada West",
                    state_id: 33,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 684,
                    name: "Akuku-Toru",
                    state_id: 33,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 685,
                    name: "Andoni",
                    state_id: 33,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 686,
                    name: "Asari-Toru",
                    state_id: 33,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 687,
                    name: "Bonny",
                    state_id: 33,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 688,
                    name: "Degema",
                    state_id: 33,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 689,
                    name: "Eleme",
                    state_id: 33,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 690,
                    name: "Emuoha",
                    state_id: 33,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 691,
                    name: "Etche",
                    state_id: 33,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 692,
                    name: "Gokana",
                    state_id: 33,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 693,
                    name: "Ikwerre",
                    state_id: 33,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 694,
                    name: "Khana",
                    state_id: 33,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 695,
                    name: "Obio/Akpor",
                    state_id: 33,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 696,
                    name: "Ogba/Egbema/Ndoni",
                    state_id: 33,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 697,
                    name: "Ogu/Bolo",
                    state_id: 33,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 698,
                    name: "Okrika",
                    state_id: 33,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 699,
                    name: "Omuma",
                    state_id: 33,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 700,
                    name: "Opobo/Nkoro",
                    state_id: 33,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 701,
                    name: "Oyigbo",
                    state_id: 33,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 702,
                    name: "Port Harcourt",
                    state_id: 33,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 703,
                    name: "Tai",
                    state_id: 33,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 34,
            name: "Sokoto",
            country_id: 152,
            lgas: [
                {
                    id: 704,
                    name: "Binji",
                    state_id: 34,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 705,
                    name: "Bodinga",
                    state_id: 34,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 706,
                    name: "Dange Shuni",
                    state_id: 34,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 707,
                    name: "Gada",
                    state_id: 34,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 708,
                    name: "Goronyo",
                    state_id: 34,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 709,
                    name: "Gudu",
                    state_id: 34,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 710,
                    name: "Gwadabawa",
                    state_id: 34,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 711,
                    name: "Illela",
                    state_id: 34,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 712,
                    name: "Isa",
                    state_id: 34,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 713,
                    name: "Kebbe",
                    state_id: 34,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 714,
                    name: "Kware",
                    state_id: 34,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 715,
                    name: "Rabah",
                    state_id: 34,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 716,
                    name: "Sabon Birni",
                    state_id: 34,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 717,
                    name: "Shagari",
                    state_id: 34,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 718,
                    name: "Silame",
                    state_id: 34,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 719,
                    name: "Sokoto North",
                    state_id: 34,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 720,
                    name: "Sokoto South",
                    state_id: 34,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 721,
                    name: "Tambuwal",
                    state_id: 34,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 722,
                    name: "Tangaza",
                    state_id: 34,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 723,
                    name: "Tureta",
                    state_id: 34,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 724,
                    name: "Wamako",
                    state_id: 34,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 725,
                    name: "Wurno",
                    state_id: 34,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 726,
                    name: "Yabo",
                    state_id: 34,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 35,
            name: "Taraba",
            country_id: 152,
            lgas: [
                {
                    id: 727,
                    name: "Ardo Kola",
                    state_id: 35,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 728,
                    name: "Bali",
                    state_id: 35,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 729,
                    name: "Donga",
                    state_id: 35,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 730,
                    name: "Gashaka",
                    state_id: 35,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 731,
                    name: "Gassol",
                    state_id: 35,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 732,
                    name: "Ibi",
                    state_id: 35,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 733,
                    name: "Jalingo",
                    state_id: 35,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 734,
                    name: "Karim Lamido",
                    state_id: 35,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 735,
                    name: "Kumi",
                    state_id: 35,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 736,
                    name: "Lau",
                    state_id: 35,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 737,
                    name: "Sardauna",
                    state_id: 35,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 738,
                    name: "Takum",
                    state_id: 35,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 739,
                    name: "Ussa",
                    state_id: 35,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 740,
                    name: "Wukari",
                    state_id: 35,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 741,
                    name: "Yorro",
                    state_id: 35,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 742,
                    name: "Zing",
                    state_id: 35,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 36,
            name: "Yobe",
            country_id: 152,
            lgas: [
                {
                    id: 743,
                    name: "Bade",
                    state_id: 36,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 744,
                    name: "Bursari",
                    state_id: 36,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 745,
                    name: "Damaturu",
                    state_id: 36,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 746,
                    name: "Fika",
                    state_id: 36,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 747,
                    name: "Fune",
                    state_id: 36,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 748,
                    name: "Geidam",
                    state_id: 36,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 749,
                    name: "Gujba",
                    state_id: 36,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 750,
                    name: "Gulani",
                    state_id: 36,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 751,
                    name: "Jakusko",
                    state_id: 36,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 752,
                    name: "Karasuwa",
                    state_id: 36,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 753,
                    name: "Machina",
                    state_id: 36,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 754,
                    name: "Nangere",
                    state_id: 36,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 755,
                    name: "Nguru",
                    state_id: 36,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 756,
                    name: "Potiskum",
                    state_id: 36,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 757,
                    name: "Tarmuwa",
                    state_id: 36,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 758,
                    name: "Yunusari",
                    state_id: 36,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 759,
                    name: "Yusufari",
                    state_id: 36,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        },
        {
            id: 37,
            name: "Zamfara",
            country_id: 152,
            lgas: [
                {
                    id: 760,
                    name: "Anka",
                    state_id: 37,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 761,
                    name: "Bakura",
                    state_id: 37,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 762,
                    name: "Birnin Magaji/Kiyaw",
                    state_id: 37,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 763,
                    name: "Bukkuyum",
                    state_id: 37,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 764,
                    name: "Bungudu",
                    state_id: 37,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 765,
                    name: "Gummi",
                    state_id: 37,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 766,
                    name: "Gusau",
                    state_id: 37,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 767,
                    name: "Kaura Namoda",
                    state_id: 37,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 768,
                    name: "Maradun",
                    state_id: 37,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 769,
                    name: "Maru",
                    state_id: 37,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 770,
                    name: "Shinkafi",
                    state_id: 37,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 771,
                    name: "Talata Mafara",
                    state_id: 37,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 772,
                    name: "Chafe",
                    state_id: 37,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                },
                {
                    id: 773,
                    name: "Zurmi",
                    state_id: 37,
                    created_at: "2017-09-29 03:30:05",
                    active_status: 1
                }
            ]
        }
    ]
}

export const eventName = {
    CYCLE_PLANNER_RESET : "web_cycle_planner_RESET",
    CYCLE_PLANNER_UPDATED: "web_cycle_planner_UPDATED",
    CYCLE_PLANNER_VIEW : "web_cycle_planner_VIEW",
    CYCLE_PLANNER_SETUP : "web_cycle_planner_SETUP",
}

