import moment from "moment";
import React from "react";

import Missed from "../img/phone-missed.svg";

export default ({ consultation }) => {
  const { last_message } = consultation;
  if (last_message && last_message.type === 4) {
    return (
      <div className="">
        <img
          src={Missed}
          alt=""
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            marginBottom: "0.5rem",
          }}
          className="dashboard-card__body-text"
        />
        <div
          className="dashboard-card__body-text"
          style={{
            marginBottom: "0.5rem",
            fontSize: "1.5rem",
            display: "inline-block",
            verticalAlign: "middle",
            paddingLeft: "1rem",
          }}
        >
          You missed call at {moment(last_message.created_at).format("hh:mm A")}
        </div>
      </div>
    );
  } else {
    return (
      <div className="">
        <p
          className="dashboard-card__body-text"
          style={{ marginBottom: "0.5rem", fontSize: "1.5rem" }}
        >
          <strong>Last Message: </strong>{" "}
          {last_message && last_message?.message
            ? last_message.message
            : "No message available"}
        </p>
      </div>
    );
  }
};
